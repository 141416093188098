import { useIntl } from 'react-intl';
import { EChart } from '../EChart.tsx';
import { EChartsOption, YAXisComponentOption } from 'echarts';
import { formatPower } from '../../utils/formatting/formatPowerConsumption.ts';
import { PowerUnit } from '../../types/consumptions.ts';

type ConsumptionChartProps = {
    isLoading: boolean;
    height?: string;
    yAxis?: YAXisComponentOption;
    xAxis?: EChartsOption['xAxis'];
    className?: string;
    maxConsumptionUnit: PowerUnit;
    series?: EChartsOption['series'];
    xAxisData: string[];
    tooltip?: EChartsOption['tooltip'];
};

export function ConsumptionChart({
    isLoading,
    height,
    className = '',
    xAxisData,
    maxConsumptionUnit,
    series,
    tooltip,
}: ConsumptionChartProps) {
    const intl = useIntl();

    return (
        <EChart
            className={className}
            height={height}
            isLoading={isLoading}
            option={{
                tooltip,
                grid: {
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    data: xAxisData,
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 10,
                    },
                },
                yAxis: {
                    type: 'value',
                    name: maxConsumptionUnit,
                    nameGap: 20,
                    nameTextStyle: {
                        fontSize: 10,
                        fontWeight: 'bold',
                        color: 'black',
                    },
                    axisLabel: {
                        fontSize: 10,
                        formatter: val => formatPower(val, intl.locale, maxConsumptionUnit).kwhLocalString,
                    },
                },
                series,
            }}
        />
    );
}
