import { useIntl } from 'react-intl';
import { useAnalysisChart } from './useAnalysisChart.ts';
import { FilterValues } from './useFilterValues.tsx';
import { Format } from '../utils/formatting';

type PriceObj = {
    sumCost: number;
};
type ConsumptionObj = {
    kwh: number;
};

export const useAnalysisChartSubtitle = ({
    filterValues,
    setFilterValues,
}: {
    filterValues: FilterValues;
    setFilterValues: (filterValues: FilterValues) => void;
}) => {
    const intl = useIntl();

    const { consumption, pvGeneration, energyCosts } = useAnalysisChart(filterValues, setFilterValues);

    const energyCostSum = (energyCosts as PriceObj[]).reduce((a, b) => a + b.sumCost, 0);

    const totalCostSubtitle = intl.formatMessage(
        {
            id: 'analysis.chartTitle.totalCostSubtitle',
        },
        {
            value: intl.formatNumber(energyCostSum, {
                maximumFractionDigits: energyCostSum >= 100 ? 0 : 2,
            }),
            unit: '€',
        },
    );

    const consumptionSum = (consumption as ConsumptionObj[]).reduce((a, b) => a + b.kwh, 0);
    const pvSum = (pvGeneration as ConsumptionObj[]).reduce((a, b) => a + b.kwh, 0);
    const consumptionWithPv = consumptionSum + pvSum;

    const totalConsumptionFormated = Format.formatConsumptionValue(consumptionWithPv);
    const totalPVFormated = Format.formatConsumptionValue(pvSum);

    const consumptionChartSubtitleWithPv = intl.formatMessage(
        {
            id: 'analysis.chartTitle.totalConsumptionSubtitle',
        },
        {
            volume: intl.formatNumber(totalConsumptionFormated.value, {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }),
            unit: totalConsumptionFormated.unit,
            pvVolume: intl.formatNumber(totalPVFormated.value, {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }),
            pvVolumeUnit: totalPVFormated.unit,
        },
    );

    return {
        costSubtitle: energyCostSum ? totalCostSubtitle : '',
        consumptionSubtitle:
            totalConsumptionFormated.value || totalPVFormated.value ? consumptionChartSubtitleWithPv : '',
    };
};
