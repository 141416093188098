type KPICardHeaderProps = {
    title: string;
    subtitle: string;
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
};
export const KPICardHeader = ({ title, subtitle, Icon }: KPICardHeaderProps) => {
    return (
        <>
            <div className="flex items-center gap-2 mb-2">
                <div className="w-6 h-6">
                    <Icon className="text-primary-blue" />
                </div>
                <div className="text-base md:text-lg xl:text-xl font-bold w-full">{title}</div>
            </div>
            <div className="text-xs">{subtitle}</div>
        </>
    );
};
