import { useController, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, InputField } from '@trawa-energy/ui-kit';
import { useAuthContext } from '../../auth/authContext.ts';
import { Credentials } from '../../auth/useAuthenticate.js';
import { analytics } from '../../analytics/analytics.ts';
import { trpc } from '../../utils/trpc.js';
import { EventName } from '../../analytics/events.ts';

export function LoginForm({ onSuccess }: { onSuccess: () => unknown }) {
    const { signIn } = useAuthContext();
    const [signInError, setSignInError] = useState<Error>();

    const [searchParams] = useSearchParams();
    const intl = useIntl();
    const accountId = searchParams.get('accountId');
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<Credentials>({
        defaultValues: {
            username: accountId ? `default-user+${accountId.replace(/-/g, '')}@trawa.de` : '',
            password: searchParams.get('password') ?? '',
        },
    });

    const usernameController = useController({
        name: 'username',
        control,
        rules: { required: true },
    });
    const passwordController = useController({
        name: 'password',
        control,
        rules: { required: true },
    });

    const { data: accountName, isLoading } = trpc.login.accountName.useQuery(
        { accountId: accountId! },
        { enabled: !!accountId },
    );

    const [isBusySubmitButton, setIsBusySubmitButton] = useState(false);

    const onSubmit = async (data: Credentials) => {
        try {
            setIsBusySubmitButton(true);
            await signIn(data);
            onSuccess();
            analytics.track(EventName.PasswordSignIn);
        } catch (error) {
            console.log(
                'Sign-in error. If the password is correct, are we using the right user pool and client IDs?',
                error,
            );
            setSignInError(error instanceof Error ? error : new Error('Unknown error'));
        } finally {
            setIsBusySubmitButton(false);
        }
    };

    function getSignInErrorMessage(signInError: Error): string {
        if (signInError.message === 'Incorrect username or password.') {
            return intl.formatMessage({ id: 'login.errors.incorrectUsernameOrPassword' });
        } else {
            return intl.formatMessage({ id: 'login.errors.incorrectUsernameOrPassword' }); // TODO: there could be other errors, but we dont want to show them #522 signInError.message;
        }
    }

    return (
        <form className="space-y-4 w-full md:max-w-md" onSubmit={handleSubmit(onSubmit)}>
            {!accountId ? (
                <InputField {...usernameController.field} label="E-mail" autoComplete="username" />
            ) : (
                <>
                    <InputField
                        label={intl.formatMessage({ id: 'login.customer' })}
                        readOnly
                        value={accountName ?? ''}
                        error={usernameController.fieldState.error?.message}
                        autoComplete="off"
                    />
                    <input type="hidden" {...usernameController.field} autoComplete="off" />
                </>
            )}
            <InputField
                {...passwordController.field}
                error={
                    passwordController.fieldState.error?.message ??
                    (signInError ? getSignInErrorMessage(signInError) : undefined)
                }
                type="password"
                label={intl.formatMessage({ id: 'login.password' })}
                autoComplete="current-password"
            />
            <Button className="w-full" type="submit" isBusy={isBusySubmitButton}>
                {intl.formatMessage({ id: 'login.button' })}
            </Button>
        </form>
    );
}
