import { useIntl } from 'react-intl';
import { EChart } from '../EChart.tsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { inferRouterOutputs } from '@trpc/server';
import { AppRouter } from '@trawa-energy/portal-api/appRouter';
import { trpc } from '../../utils/trpc.ts';
import { Temporal } from 'temporal-polyfill';
import { ECharts, SeriesOption } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { EventName } from '../../analytics';
import { analytics } from '../../analytics/analytics.ts';
import { EChartFuturePricesContext } from '../../context/EChartFuturePricesContext.tsx';

export type FuturePricesEntry = inferRouterOutputs<AppRouter>['prices']['getFuturePricesByRange'][0];

const lineColorMap = {
    base: { 1: '#055E45', 2: '#1FB274', 3: '#b4f9dc' },
    peak: { 1: '#055E45', 2: '#1FB274', 3: '#7BEABB' }, // we do not look at peak right now
} as const;

type EChartEventDataZoom = {
    type: 'datazoom';
    // percentage of zoom start position, 0 - 100
    start: number;
    // percentage of zoom finish position, 0 - 100
    end: number;
    // data value of zoom start position; only exists in zoom event of triggered by toolbar
    startValue?: number;
    // data value of zoom finish position; only exists in zoom event of triggered by toolbar
    endValue?: number;
};

type EChartEventLegendSelectChanged = {
    type: 'legendselectchanged';
    // change legend name
    name: string;
    // table of all legend selecting states
    selected: Record<string, boolean>;
};

export function FuturePricesChart() {
    const intl = useIntl();

    /* we require the future prices for the last 12 months, the api currently only returns base for Y1, Y2, Y3 by default */
    const { data: futurePrices, isLoading: isLoadingFuturePrices } = trpc.prices.getFuturePricesByRange.useQuery({
        dateRange: {
            from: Temporal.Now.plainDateISO().subtract({ months: 12 }).toString(),
            exclusiveTo: Temporal.Now.plainDateISO().toString(),
        },
    });

    const [chart, setChart] = useState<ECharts | undefined>();

    useEffect(() => {
        chart?.on('datazoom', function (params) {
            const { start, end } = params as EChartEventDataZoom;
            analytics.track(EventName.ProductsFuturePricesDataZoom, {
                Start: start,
                End: end,
            });
        });
        chart?.on('legendselectchanged', function (params) {
            const { name, selected } = params as EChartEventLegendSelectChanged;
            analytics.track(EventName.ProductsFuturePricesToggleChart, {
                'Legend option toggled': name,
                'Legend option chosen': selected,
            });
        });
    }, [chart]);

    const itemTooltipFormatter = useCallback(
        (params: CallbackDataParams) => {
            const baseYear = (params.data as FuturePricesEntry)?.symbolKey?.split('Y')[1];
            const currentYear = Temporal.Now.plainDateISO().year;
            const futurePriceYear = currentYear + parseInt(baseYear);

            const [date, price] = params?.value as string[];
            return `<div>
                             <p>${intl.formatMessage(
                                 { id: 'products.chart.tooltip.date' },
                                 {
                                     date: intl.formatDate(date),
                                     time: intl.formatTime(date),
                                     b: (chunks: string[]) => `<strong>${chunks[0]}</strong>`,
                                 },
                             )}</p>
                                        <p>${intl.formatMessage(
                                            { id: 'products.chart.tooltip.price' },
                                            {
                                                value: intl.formatNumber(Number(price), {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }),
                                                year: futurePriceYear,
                                                unit: 'Eur/MWh',
                                                b: (chunks: string[]) => {
                                                    console.log('chunks', chunks[0]);
                                                    return `<strong>${chunks[0]}</strong>`;
                                                },
                                            },
                                        )} </p>
                                        </div>`;
        },
        [intl],
    );

    const seriesOptions = useMemo(() => {
        return futurePrices?.map((priceObj: FuturePricesEntry) => {
            return {
                name: `${priceObj.symbolKey}-${priceObj.year}`,
                type: 'line',
                symbolKey: 'circle',
                colorBy: 'series',
                color: lineColorMap[priceObj.symbolKey as 'base'][priceObj.year as 1 | 2 | 3],

                data:
                    priceObj?.data?.map(x => {
                        return {
                            ...x,
                            value: [Temporal.PlainDateTime.from(x.sourceUpdateTime).toString(), x.lastPrice],
                        };
                    }) ?? [],
                emphasis: {
                    focus: 'series',
                },
                tooltip: {
                    formatter: itemTooltipFormatter,
                },
                lineStyle: { color: lineColorMap[priceObj.symbolKey as 'base'][priceObj.year as 1 | 2 | 3] },
            } as SeriesOption;
        });
    }, [futurePrices, itemTooltipFormatter]);

    return (
        <EChartFuturePricesContext.Provider value={{ chart, setChart }}>
            <EChart
                locale="DE"
                isLoading={isLoadingFuturePrices}
                option={{
                    title: {
                        text: intl.formatMessage({ id: 'products.chart.title' }),
                        left: 'left',
                        padding: [10, 0, 0, 15],
                    },
                    grid: {
                        left: '3%',
                        right: '3%',
                        bottom: '10%',
                        top: 90,
                        containLabel: true,
                    },
                    legend: {
                        type: 'plain',
                        right: 15,
                        top: 40,
                        show: true,

                        data: futurePrices?.map(priceObj => ({
                            name: `${priceObj.symbolKey}-${priceObj.year}`,
                            itemStyle: {
                                color: lineColorMap[priceObj.symbolKey as 'base'][priceObj.year as 1 | 2 | 3],
                            },
                        })),
                        formatter: name => {
                            const [product, year] = name.split('-');
                            return intl.formatMessage(
                                { id: 'products.chart.legend' },
                                {
                                    product: product.toUpperCase(),
                                    year: year,
                                },
                            );
                        },
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    yAxis: {
                        type: 'value',
                        scale: true,
                        splitLine: {
                            show: false,
                        },
                        nameGap: 20,
                        name: '€ / MWh',
                        nameLocation: 'end',
                        nameTextStyle: {
                            fontSize: 14,
                            color: 'black',
                        },
                    },
                    xAxis: {
                        type: 'time',
                        splitLine: {
                            show: true,
                        },
                        axisLabel: {
                            formatter: {
                                year: '{MMM} {yyyy}',
                                month: '{MMM} {yyyy}',
                                day: '{d} {MMM}',
                            },
                        },
                    },
                    series: seriesOptions,
                    dataZoom: [
                        {
                            type: 'slider',
                            show: true,
                            realtime: true,
                            start: 70,
                            end: 100,
                            labelFormatter: function (_value, valueStr) {
                                return intl.formatDate(valueStr, {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                });
                            },
                        },
                    ],
                }}
            />
        </EChartFuturePricesContext.Provider>
    );
}
