import { trpc } from '../../utils/trpc.js';
import { getPlainDateTimeFromString } from '../../utils/getPlainDateTimeFromString';
import { Temporal } from 'temporal-polyfill';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { formatEnergy } from '../../utils/formatting/formatPowerConsumption.js';

// get the number of months between two dates + the fraction of the last month
const getNumOfMonths = (startDate: Temporal.PlainDate, endDate: Temporal.PlainDate) => {
    return endDate.since(startDate).total({ unit: 'months', relativeTo: startDate });
};

export const useGetTotalConsumptionInfo = () => {
    const intl = useIntl();
    const [today] = useState(Temporal.Now.plainDateISO(intl.timeZone!));
    const { data: totalConsumptions, isLoading } = trpc.dashboard.getTotalConsumption.useQuery({});

    const { totalKWh, firstIngestionDate: startDate } = totalConsumptions ?? {};
    if (!totalKWh || !startDate) {
        return {
            isLoading,
        };
    }
    const totalKWhValue = Number(totalKWh);
    const firstIngestionDate = getPlainDateTimeFromString(startDate).toPlainDate();
    const averageConsumption =
        totalKWhValue / getNumOfMonths(getPlainDateTimeFromString(startDate).toPlainDate(), today);
    const isTotalConsumptionDataReady = !!(totalConsumptions && averageConsumption && startDate);
    const title = intl.formatMessage({ id: 'kpi.consumption.title' });
    const subtitle = isTotalConsumptionDataReady
        ? intl.formatMessage(
              { id: 'kpi.consumption.subtitle' },
              {
                  totalConsumptions: formatEnergy(totalKWhValue, intl.locale),
                  averageConsumption: formatEnergy(averageConsumption, intl.locale),
                  firstIngestionDate: intl.formatDate(firstIngestionDate.toString(), {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                  }),
              },
          )
        : intl.formatMessage({ id: 'kpi.consumption.noDataAvailableSubtitle' });

    return {
        totalConsumptions: totalKWhValue,
        averageConsumption,
        firstIngestionDate,
        isLoading,
        title,
        subtitle,
    };
};
