import { CSSProperties, useContext, useEffect, useRef } from 'react';
import { EChartsOption, getInstanceByDom, init, SetOptionOpts } from 'echarts';
import 'echarts/i18n/langDE';

import { EChartAnalysisContext } from '../context/EChartAnalysisContext.tsx';
import { useIntl } from 'react-intl';
import { EChartFuturePricesContext } from '../context/EChartFuturePricesContext.tsx';

type Props = {
    option: EChartsOption;
    style?: CSSProperties;
    settings?: SetOptionOpts;
    isLoading?: boolean;
    theme?: 'light' | 'dark';
    testId?: string;
    height?: string;
    className?: string;
    renderer?: 'canvas' | 'svg';
    locale?: string;
};

export const EChart = ({
    option,
    style,
    settings,
    isLoading = false,
    theme = 'light',
    testId,
    height = '500px',
    className = '',
    renderer = 'svg',
    locale = 'EN',
}: Props) => {
    const intl = useIntl();
    const chartRef = useRef<HTMLDivElement>(null);

    const { setChart } = useContext(EChartAnalysisContext);
    const { setChart: setFuturePricesChart } = useContext(EChartFuturePricesContext);
    useEffect(() => {
        const chart =
            chartRef.current !== null
                ? init(chartRef.current, theme, {
                      renderer,
                      locale,
                  })
                : undefined;

        // Add chart resize listener
        // ResizeObserver is leading to a bit janky UX
        const resizeChart = () => {
            chart?.resize();
        };

        window.addEventListener('resize', resizeChart);

        return () => {
            window.removeEventListener('resize', resizeChart);
        };
    }, [theme, renderer, locale]);

    useEffect(() => {
        const chart = chartRef.current !== null ? getInstanceByDom(chartRef.current) : undefined;

        return () => {
            chart?.dispose();
        };
    }, []);

    // Update chart
    useEffect(() => {
        const chart = chartRef.current !== null ? getInstanceByDom(chartRef.current) : undefined;

        if (chart === undefined) {
            return;
        }

        // set chart context value
        setChart?.(chart);
        setFuturePricesChart?.(chart);

        chart.setOption(option, settings);
    }, [option, settings, theme, setChart, setFuturePricesChart]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

    // Update chart
    useEffect(() => {
        const chart = chartRef.current !== null ? getInstanceByDom(chartRef.current) : undefined;

        if (chart === undefined) {
            return;
        }

        isLoading
            ? chart.showLoading({
                  text: intl.formatMessage({ id: 'common.loading' }),
                  color: '#5A5A5A',
              })
            : chart.hideLoading();
    }, [isLoading, theme, intl]);

    return (
        <div className={className} ref={chartRef} style={{ width: '100%', height, ...style }} data-testid={testId} />
    );
};
