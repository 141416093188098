import { PauseIcon, PlayIcon } from '@trawa-energy/ui-kit';
import { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Temporal } from 'temporal-polyfill';
import { analytics } from '../../analytics/analytics.ts';
import { ConsumptionGridDatepicker } from './ConsumptionGridDatepicker';
import { EventName } from '../../analytics/events.ts';

const generateSelectableDateTimes = (minDateTime: Temporal.PlainDateTime) => {
    const maxDateTime = minDateTime.add({ hours: 24 });
    const addDateTimesRecursively = (
        dateTimeToAdd: Temporal.PlainDateTime,
        array: Temporal.PlainDateTime[],
    ): Temporal.PlainDateTime[] => {
        if (Temporal.PlainDateTime.compare(dateTimeToAdd, maxDateTime) === 0) {
            return array;
        }

        return addDateTimesRecursively(dateTimeToAdd.add({ minutes: 15 }), [...array, dateTimeToAdd]);
    };

    return addDateTimesRecursively(minDateTime, []);
};

export const TimestampSlider: FC<{
    selectedDate: Temporal.PlainDate;
    setSelectedDate: (startDate: Temporal.PlainDate) => void;
    value: Temporal.PlainDateTime;
    onChange: (value: Temporal.PlainDateTime) => void;
}> = ({ selectedDate, value, onChange, setSelectedDate }) => {
    const intl = useIntl();
    const [autoSlideIsEnabled, setAutoSlideIsEnabled] = useState(true);
    const minDateTime = selectedDate.toPlainDateTime();
    const selectableDateTimes = useMemo(() => generateSelectableDateTimes(minDateTime), [minDateTime]);
    const selectedDateTimeIndex = selectableDateTimes.findIndex(dateTime => value.equals(dateTime));
    const percentage = (selectedDateTimeIndex / (selectableDateTimes.length - 1)) * 100;

    useEffect(() => {
        const interval = setInterval(() => {
            if (!autoSlideIsEnabled) {
                return;
            }

            const nextIndex = selectedDateTimeIndex === selectableDateTimes.length - 1 ? 0 : selectedDateTimeIndex + 1;

            onChange(selectableDateTimes[nextIndex]);
        }, 6250);

        return () => clearInterval(interval);
    }, [autoSlideIsEnabled, onChange, selectableDateTimes, selectedDateTimeIndex, value]);

    const tailwindClasses = [
        'w-[calc(100%+1.5rem)]', // full-w + thumb-width
        'h-8',
        'block',
        'bg-transparent',
        'cursor-pointer',
        'relative',
        'z-20',
        'appearance-none',
        '[&::-webkit-slider-thumb]:appearance-none',
        '[&::-moz-range-thumb]:appearance-none',

        '-ml-3', // thumb-width / 2
        'mb-2',

        // // slider thumb
        '[&::-webkit-slider-thumb]:w-6',
        '[&::-moz-range-thumb]:w-6',

        '[&::-webkit-slider-thumb]:h-6',
        '[&::-moz-range-thumb]:h-6',

        '[&::-webkit-slider-thumb]:shadow-[0_0_0_2px_rgba(30,64,175,1)]',
        '[&::-moz-range-thumb]:shadow-[0_0_0_1px_rgba(30,64,175,1)]',
        '[&::-moz-range-thumb]:border-blue-800',

        '[&::-webkit-slider-thumb]:bg-white',
        '[&::-moz-range-thumb]:bg-white',

        '[&::-webkit-slider-thumb]:rounded-full',
        '[&::-moz-range-thumb]:rounded-full',
    ];

    return (
        <div
            data-testid="grid_timestamp_slider"
            className="w-full grid grid-cols-1 sm:grid-cols-3 gap-y-8 gap-x-6 bg-primary-blue-1 px-3 py-8 md:px-8 border-t border-muted-foreground"
        >
            <div className="order-2 sm:order-1 justify-self-center sm:justify-self-end">
                <div className="flex items-center gap-4">
                    <div>
                        <button
                            type="button"
                            className="flex items-center gap-2 border px-4 py-3 rounded-xl hover:text-primary bg-white font-bold"
                            onClick={() => {
                                setAutoSlideIsEnabled(prevState => !prevState);
                                analytics.track(EventName.GridViewAnimationToggled, {
                                    'Toggle State': autoSlideIsEnabled ? 'paused' : 'playing',
                                });
                            }}
                        >
                            {autoSlideIsEnabled ? <PauseIcon /> : <PlayIcon />}
                        </button>
                    </div>
                    <div>
                        <ConsumptionGridDatepicker
                            label={intl.formatDate(value.toString(), { dateStyle: 'medium' })}
                            selected={new Date(selectedDate.toString())}
                            onChange={date => {
                                setSelectedDate(
                                    Temporal.PlainDate.from({
                                        year: date.getFullYear(),
                                        month: date.getMonth() + 1 /* 0-based */,
                                        day: date.getDate(),
                                    }),
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="px-8 order-1 sm:order-2 col-span-2 2xl:col-span-1">
                <div className="relative">
                    <div className="relative">
                        <input
                            className={tailwindClasses.join(' ')}
                            type="range"
                            min={0}
                            max={selectableDateTimes.length - 1}
                            value={selectableDateTimes.findIndex(x => value.equals(x))}
                            onChange={event => onChange(selectableDateTimes[parseInt(event.target.value, 10)])}
                            onMouseDown={() => setAutoSlideIsEnabled(false)}
                            onMouseUp={() => {
                                analytics.track(EventName.GridViewRendered, {
                                    Date: value.toZonedDateTime(intl.timeZone!).toString(),
                                });
                            }}
                        />
                        <div className="h-1 bg-blue-600 absolute top-1/2 w-full -translate-y-1/2 z-10" />
                        <div className="grid grid-cols-3 absolute top-1/2 w-full">
                            <div className="border-l-2 h-4" />
                            <div className="border-x-2 h-3" />
                            <div className="border-r-2 h-4" />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <span className="-translate-x-1/2">{intl.formatTime(selectableDateTimes[0].toString())}</span>
                        <output
                            className="absolute -translate-x-1/2 -translate-y-1 w-16 bg-gray-6 rounded-xl text-center text-white px-2 py-1 z-20"
                            style={{ left: `${percentage}%` }}
                        >
                            {intl.formatTime(value.toString())}
                        </output>
                        <span className="translate-x-1/2">
                            {intl.formatTime(selectableDateTimes[selectableDateTimes.length - 1].toString())}
                        </span>
                    </div>
                </div>
            </div>
            <div className="hidden sm:block order-3" />
        </div>
    );
};
