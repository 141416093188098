import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';
import { analytics } from '../analytics/analytics.ts';
import { PartialUserSettings } from '../userSettings';

export const Root = () => {
    const location = useLocation();

    useEffect(() => {
        analytics.page();
    }, [location.pathname]);

    return <Outlet />;
};

export type OutletContext = PartialUserSettings | undefined;
