export type TrackParams = {
    anonymousId: string;
    event: keyof typeof EventName;
    options: Record<string, unknown>;
    properties: Record<string, unknown>;
    type: 'track';
    userId: string | null;
};

/* we do have some track call, that have no properties */
export type TrackMethod = {
    <EventName extends keyof EventProperties>(
        event: EventName,
        args: EventProperties[EventName] extends undefined ? never : EventProperties[EventName],
    ): void;
    <EventName extends keyof EventProperties>(
        event: Extract<
            EventName,
            keyof {
                [K in keyof EventProperties]: EventProperties[K] extends undefined ? K : never;
            }
        >,
    ): void;
};

export type PageParams = {
    anonymousId: string;
    options: Record<string, unknown>;
    properties: {
        title: string;
        path: string;
        hash: string;
        search: string;
    };
    type: 'page';
    userId: string | null;
};

export type IdentifyParams = {
    anonymousId: string;
    userId: string | null;
    type: 'identify';
};

export const EventName = {
    PageViewed: 'Page viewed',
    GridViewRendered: 'Grid rendered',
    GridViewAnimationToggled: 'Animation toggled',
    ConsumptionReportRendered: 'Report rendered',
    ErrorPageShown: 'Error page shown',
    FeedbackFormOpen: 'Feedback form open',
    TVScreenViewed: 'TV screen viewed',
    TVScreenAccessDenied: 'TV screen access denied',
    TVScreenUnloaded: 'TV screen unloaded',
    TVScreenLoaded: 'TV screen loaded',
    AnalysisSelectTimePeriod: 'Analysis select time period',
    AnalysisSelectLocation: 'Analysis select location',
    AnalysisSelectTimeInterval: 'Analysis select time interval',
    AnalysisToggleChart: 'Analysis toggle chart',
    DocumentsDownloadSingle: 'Documents download single',
    DocumentsDownloadAll: 'Documents download all',
    DocumentsSelectTimeRange: 'Documents select time range',
    DocumentsFilterType: 'Documents filter type',
    DocumentsFilterMarketLocation: 'Documents filter market location',
    MagicLinkSignIn: 'Sign In with Magic Link',
    PasswordSignIn: 'Sign In with Password',
    OverviewKpiCardClicked: 'KPI card link clicked',
    ProductsFuturePricesToggleChart: 'Future prices toggle chart',
    ProductsFuturePricesDataZoom: 'Future prices data zoom',
} as const;

// Define properties for each event
export interface EventProperties {
    [EventName.PageViewed]: { page: string; referrer?: string };
    [EventName.GridViewRendered]: { Date: string };
    [EventName.GridViewAnimationToggled]: { 'Toggle State': 'paused' | 'playing' };
    [EventName.ConsumptionReportRendered]: {
        'Report Name': 'Actual Consumption';
        'Time Interval': string;
        'Time Preset': string;
        'Date Range': string;
        'Report View Type': 'chart' | 'table';
    };
    [EventName.MagicLinkSignIn]: { userId: string };
    [EventName.PasswordSignIn]: undefined;
    [EventName.TVScreenViewed]: { 'Date shown': string; 'Account ID': string };
    [EventName.TVScreenLoaded]: {
        'Account ID': string;
    };
    [EventName.TVScreenUnloaded]: {
        'Account ID': string;
    };
    [EventName.TVScreenAccessDenied]: {
        'Account ID'?: string;
    };
    [EventName.DocumentsDownloadAll]: { 'Document type': string; 'Market location': string; 'Time range': string };
    [EventName.DocumentsDownloadSingle]: { 'Document type': string; 'Market location': string; 'Time range': string };
    [EventName.DocumentsSelectTimeRange]: { 'Document type': string; 'Market location': string; 'Time range': string };
    [EventName.DocumentsFilterMarketLocation]: { 'Market location': string };
    [EventName.DocumentsFilterType]: { 'Document type': string };
    [EventName.AnalysisToggleChart]: { 'Legend option chosen': Record<string, boolean> };
    [EventName.AnalysisSelectLocation]: { 'Market location': string };
    [EventName.AnalysisSelectTimePeriod]: { 'Time period': string };
    [EventName.AnalysisSelectTimeInterval]: { 'Time interval': string };
    [EventName.ErrorPageShown]: { 'Error path': string };
    [EventName.FeedbackFormOpen]: undefined;
    [EventName.OverviewKpiCardClicked]: { Link: string; Card: string };
    [EventName.ProductsFuturePricesDataZoom]: { Start: number; End: number };
    [EventName.ProductsFuturePricesToggleChart]: {
        'Legend option toggled': string;
        'Legend option chosen': Record<string, boolean>;
    };
}
