import React, { useLayoutEffect, useState } from 'react';
import { Card } from '../components/Card';
import { ExclamationTriangleIcon, MessageIcon } from '@trawa-energy/ui-kit';
import { useIntl } from 'react-intl';
import { analytics, EventName } from '../analytics';
import { useLocation } from 'react-router';

const NotFoundPage: React.FC = () => {
    const location = useLocation();
    const [previousLocation] = useState(location.pathname);

    useLayoutEffect(() => {
        // TODO: Improve the styling
        document.documentElement.classList.add('dark');

        analytics.track(EventName.ErrorPageShown, {
            'Error path': previousLocation,
        });
    }, [previousLocation]);

    const intl = useIntl();

    return (
        <section className="page-not-found flex justify-center items-center h-screen -mt-24">
            <Card className="max-w-xl min-w-[320px] space-y-8 p-8">
                <div className="text-xl font-bold flex gap-2 items-center">
                    <ExclamationTriangleIcon className="text-orange" />
                    <span>{intl.formatMessage({ id: 'pageNotFound.title' })}</span>
                </div>
                <div>{intl.formatMessage({ id: 'pageNotFound.body' })}</div>
                <div>
                    <strong>{intl.formatMessage({ id: 'pageNotFound.tip' })}</strong>:{' '}
                    {intl.formatMessage({ id: 'pageNotFound.tipBody' })}
                </div>
                <div className="flex gap-1">
                    <MessageIcon className="text-primary current-color" />
                    <a href="mailto:info@trawa.de" className="font-bold border-b-2 border-y-primary">
                        info@trawa.de
                    </a>
                </div>
            </Card>
        </section>
    );
};

export default NotFoundPage;
