import { FC, useEffect, useId, useState } from 'react';
import { useWindowSize } from '../hooks/useWindowSize.ts';
import { config } from '../config.ts';
type AnchorPositions = 'top' | 'right' | 'bottom' | 'left';

type AnchorOptions = {
    position: AnchorPositions;
    offset: {
        x: number;
        y: number;
    };
};

type Props = {
    start: string;
    end: string;
    startAnchor: AnchorPositions | AnchorOptions;
    endAnchor: AnchorPositions | AnchorOptions;
    dashColor?: string;
    strokeWidth?: number;
    animationSpeedPercentage?: number;
    animationIsInverted?: boolean;
    gridBreak?: number;
};

export const GridLine: FC<Props> = ({
    start,
    end,
    dashColor = 'black',
    strokeWidth = 10,
    startAnchor,
    endAnchor,
    animationSpeedPercentage = 0,
    animationIsInverted = false,
    gridBreak = 0,
}) => {
    const [startElement, setStartElement] = useState(document.getElementById(start));
    const [endElement, setEndElement] = useState(document.getElementById(end));
    const id = useId().replace(/:/g, '');

    // re-render on window resize
    useWindowSize();

    useEffect(() => {
        setStartElement(document.getElementById(start));
        setEndElement(document.getElementById(end));
    }, [end, start]);

    if (startElement === null || endElement === null) {
        return undefined;
    }

    const startAnchorPosition = typeof startAnchor === 'string' ? startAnchor : startAnchor.position;
    const endAnchorPosition = typeof endAnchor === 'string' ? endAnchor : endAnchor.position;
    const startRect = startElement.getBoundingClientRect();
    const endRect = endElement.getBoundingClientRect();

    const calcX = (rect: DOMRect, anchorPosition: AnchorPositions) => {
        const map: Record<AnchorPositions, number> = {
            top: rect.x + rect.width / 2,
            right: rect.x + rect.width,
            bottom: rect.x + rect.width / 2,
            left: rect.x,
        };

        return map[anchorPosition];
    };

    const calcY = (rect: DOMRect, anchorPosition: AnchorPositions) => {
        const map: Record<AnchorPositions, number> = {
            top: rect.y,
            right: rect.y + rect.height / 2,
            bottom: rect.y + rect.height,
            left: rect.y + rect.height / 2,
        };

        return map[anchorPosition];
    };

    const startX = calcX(startRect, startAnchorPosition);
    const startY = calcY(startRect, startAnchorPosition);
    const endX = calcX(endRect, endAnchorPosition);
    const endY = calcY(endRect, endAnchorPosition);

    const topLeftX = Math.min(startX, endX) + window.scrollX;
    const topLeftY = Math.min(startY, endY) + window.scrollY;
    const bottomRightX = Math.max(startX, endX) + window.scrollX;
    const bottomRightY = Math.max(startY, endY) + window.scrollY;

    const padding = strokeWidth / 2;
    const width = bottomRightX - topLeftX + padding * 2;
    const height = bottomRightY - topLeftY + padding * 2;

    const isGoingDown = startY < endY;
    const isGoingRight = startX < endX;

    const offsetStartX = typeof startAnchor === 'object' ? startAnchor.offset.x : 0;
    const offsetStartY = typeof startAnchor === 'object' ? startAnchor.offset.y : 0;
    const offsetEndX = typeof endAnchor === 'object' ? endAnchor.offset.x : 0;
    const offsetEndY = typeof endAnchor === 'object' ? endAnchor.offset.y : 0;

    const drawStartX = (isGoingRight ? padding : width - padding) + offsetStartX;
    const drawEndX = (isGoingRight ? width - padding : padding) + offsetEndX;
    const drawStartY = (isGoingDown ? padding : height - padding) + offsetStartY;
    const drawEndY = (isGoingDown ? height - padding : padding) + offsetEndY;

    const breakPositionHorizontal = width / 2 + gridBreak;
    const breakPositionVertical = height / 2 + gridBreak;

    const radiusSize = 18;

    const shouldAnimateGrid = config.animateGrid === 'true';

    const checkIfThereIsEnoughSpaceForBreak = (start: number, end: number) => {
        const difference = Math.max(start, end) - Math.min(start, end);

        return difference < radiusSize * 2;
    };

    type DimensionPosition = 'horizontal' | 'vertical';

    const anchorPositionToDimensionPositionMap: Record<AnchorPositions, DimensionPosition> = {
        top: 'vertical',
        right: 'horizontal',
        bottom: 'vertical',
        left: 'horizontal',
    };

    const anchorToPathMap: Record<`${DimensionPosition}-to-${DimensionPosition}`, string> = {
        'horizontal-to-horizontal': checkIfThereIsEnoughSpaceForBreak(drawStartY, drawEndY)
            ? `
                M ${drawStartX},${drawStartY}
                L ${drawEndX},${drawEndY}`
            : `
                M ${drawStartX},${drawStartY}
                L ${breakPositionHorizontal - radiusSize * (isGoingRight ? 1 : -1)},${drawStartY}
                Q ${breakPositionHorizontal},${drawStartY}
                  ${breakPositionHorizontal},${drawStartY - radiusSize * (isGoingDown ? -1 : 1)}
                L ${breakPositionHorizontal},${drawEndY - radiusSize * (isGoingDown ? 1 : -1)}
                Q ${breakPositionHorizontal},${drawEndY}
                  ${breakPositionHorizontal + radiusSize * (isGoingRight ? 1 : -1)},${drawEndY}
                L ${drawEndX},${drawEndY}
        `,
        'horizontal-to-vertical': `
            M ${drawStartX},${drawStartY}
            L ${drawEndX - radiusSize * (isGoingRight ? 1 : -1)},${drawStartY}
            Q ${drawEndX},${drawStartY}
              ${drawEndX},${drawStartY - radiusSize * (isGoingDown ? -1 : 1)}
            L ${drawEndX},${drawEndY}
            `,
        'vertical-to-horizontal': `
            M ${drawStartX},${drawStartY}
            L ${drawStartX},${drawEndY - radiusSize * (isGoingDown ? 1 : -1)}
            Q ${drawStartX},${drawEndY}
              ${drawStartX + radiusSize * (isGoingRight ? 1 : -1)},${drawEndY}
            L ${drawEndX},${drawEndY}
        `,
        'vertical-to-vertical': checkIfThereIsEnoughSpaceForBreak(drawStartX, drawEndX)
            ? `
                M ${drawStartX},${drawStartY}
                L ${drawEndX},${drawEndY}`
            : `
                M ${drawStartX},${drawStartY}
                L ${drawStartX},${breakPositionVertical - radiusSize * (isGoingDown ? 1 : -1)}
                Q ${drawStartX},${breakPositionVertical}
                  ${drawStartX - radiusSize * (isGoingRight ? -1 : 1)},${breakPositionVertical}
                L ${drawEndX - radiusSize * (isGoingRight ? 1 : -1)},${breakPositionVertical}
                Q ${drawEndX},${breakPositionVertical}
                  ${drawEndX},${breakPositionVertical + radiusSize * (isGoingDown ? 1 : -1)}
                L ${drawEndX},${drawEndY}
        `,
    };

    const calcAnimationTime = () => {
        const maxTime = 10;
        const minTime = 0.7;
        const result = ((100 - animationSpeedPercentage) * (maxTime - minTime)) / 100 + minTime;

        if (result < minTime) {
            return minTime;
        }

        if (result > maxTime) {
            return maxTime;
        }

        return result;
    };

    const animationSpeed = `${calcAnimationTime()}s`;
    const inverse = animationIsInverted ? 'Inverse' : '';

    const commonPathProps = {
        fill: 'none',
        d: anchorToPathMap[
            `${anchorPositionToDimensionPositionMap[startAnchorPosition]}-to-${anchorPositionToDimensionPositionMap[endAnchorPosition]}`
        ],
    };
    const commonDashProps = {
        ...commonPathProps,
        stroke: animationSpeedPercentage === 0 ? 'transparent' : dashColor,
        strokeWidth: strokeWidth / 3,
    };

    const calcDashLength = () => {
        const maxLength = 35;
        const minLength = 10;
        const result = (animationSpeedPercentage * (maxLength - minLength)) / 100 + minLength;

        if (result < minLength) {
            return minLength;
        }

        if (result > maxLength) {
            return maxLength;
        }

        return result;
    };

    const calcGapLength = () => {
        const maxLength = 320;
        const minLength = 120;
        const result = ((100 - animationSpeedPercentage) * (minLength - maxLength)) / 100 + maxLength;

        if (result < minLength) {
            return minLength;
        }

        if (result > maxLength) {
            return maxLength;
        }

        return result;
    };

    const dashLength = calcDashLength();
    const dashOffset = 0;
    const gapLength = shouldAnimateGrid ? calcGapLength() : 0;
    const gradientLength = dashLength / 2;

    return (
        <svg
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            height={height}
            style={{
                position: 'absolute',
                top: topLeftY - padding,
                left: topLeftX - padding,
                pointerEvents: 'none',
            }}
        >
            <style>
                {`@keyframes animateDot-${id} {
                    from { stroke-dashoffset: ${dashLength + gapLength + dashOffset - dashLength}; }
                    to   { stroke-dashoffset: ${dashOffset - dashLength}; }
                }
                @keyframes animateDash-${id} {
                    from { stroke-dashoffset: ${dashLength + gapLength + dashOffset}; }
                    to   { stroke-dashoffset: ${dashOffset}; }
                }
                @keyframes animateGradient1-${id} {
                    from { stroke-dashoffset: ${dashLength + gapLength + dashOffset + 2 * gradientLength}; }
                    to   { stroke-dashoffset: ${dashOffset + 2 * gradientLength}; }
                }
                @keyframes animateGradient2-${id} {
                    from { stroke-dashoffset: ${dashLength + gapLength + dashOffset + 3 * gradientLength}; }
                    to   { stroke-dashoffset: ${dashOffset + 3 * gradientLength}; }
                }
                @keyframes animateGradient3-${id} {
                    from { stroke-dashoffset: ${
                        dashLength + gapLength + dashOffset + (3 / 2) * gradientLength + 2 * gradientLength
                    }; }
                    to   { stroke-dashoffset: ${dashOffset + (3 / 2) * gradientLength + 2 * gradientLength}; }
                }

                @keyframes animateDotInverse-${id} {
                    from { stroke-dashoffset: ${dashOffset}; }
                    to   { stroke-dashoffset: ${dashLength + gapLength + dashOffset}; }
                }
                @keyframes animateDashInverse-${id} {
                    from { stroke-dashoffset: ${dashOffset}; }
                    to   { stroke-dashoffset: ${dashLength + gapLength + dashOffset}; }
                }
                @keyframes animateGradient1Inverse-${id} {
                    from { stroke-dashoffset: ${dashOffset - dashLength}; }
                    to   { stroke-dashoffset: ${dashLength + gapLength + dashOffset - dashLength}; }
                }
                @keyframes animateGradient2Inverse-${id} {
                    from { stroke-dashoffset: ${dashOffset - dashLength}; }
                    to   { stroke-dashoffset: ${dashLength + gapLength + dashOffset - dashLength}; }
                }
                @keyframes animateGradient3Inverse-${id} {
                    from { stroke-dashoffset: ${dashOffset - dashLength - 2 * gradientLength}; }
                    to   { stroke-dashoffset: ${
                        dashLength + gapLength + dashOffset - dashLength - 2 * gradientLength
                    }; }
                }`}
            </style>
            <path
                id="background"
                className="dark:stroke-gray-5 stroke-gray-1"
                strokeWidth={strokeWidth}
                {...commonPathProps}
            />
            <path
                id="gradient-3"
                strokeDasharray={`${gradientLength / 2} ${gradientLength / 2} ${gradientLength / 2} ${
                    dashLength + gapLength - (3 / 2) * gradientLength
                }`}
                strokeDashoffset={dashOffset - dashLength - 2 * gradientLength}
                strokeOpacity="0.1"
                style={{ animation: `animateGradient3${inverse}-${id} ${animationSpeed} linear infinite` }}
                {...commonDashProps}
            />
            <path
                id="gradient-2"
                strokeDasharray={`${gradientLength} ${gradientLength} ${gradientLength} ${
                    dashLength + gapLength - 3 * gradientLength
                }`}
                strokeDashoffset={dashOffset - dashLength}
                strokeOpacity="0.2"
                style={{ animation: `animateGradient2${inverse}-${id} ${animationSpeed} linear infinite` }}
                {...commonDashProps}
            />
            <path
                id="gradient-1"
                strokeDasharray={`${2 * gradientLength} ${dashLength + gapLength - gradientLength * 2}`}
                strokeDashoffset={dashOffset - dashLength}
                strokeOpacity="0.5"
                style={{ animation: `animateGradient1${inverse}-${id} ${animationSpeed} linear infinite` }}
                {...commonDashProps}
            />
            <path
                id="path"
                strokeDasharray={`${dashLength} ${gapLength}`}
                strokeDashoffset={dashOffset}
                style={{ animation: `animateDash${inverse}-${id} ${animationSpeed} linear infinite` }}
                {...commonDashProps}
            />
            <path
                id="dot"
                strokeDasharray={`1 ${dashLength + gapLength - 1}`}
                strokeDashoffset={dashOffset}
                strokeLinecap="round"
                style={{ animation: `animateDot${inverse}-${id} ${animationSpeed} linear infinite` }}
                {...commonDashProps}
            />
        </svg>
    );
};
