import { CustomItemTooltip } from '../../types/chart';
import { formatValue } from '../formatting/formatValueToFloat';
import { formatPower } from '../formatting/formatPowerConsumption';
import { Temporal } from 'temporal-polyfill';
import { IntlShape } from 'react-intl';
import { PowerUnit } from '../../types/consumptions';

const formatPowerPercentage = (portion: number, consumptions: number[], dataIndex: number, intl: IntlShape) => {
    return formatValue((portion / consumptions[dataIndex]) * 100, intl, 0, 0);
};

const formatTooltipValue = (
    value: string | number,
    intl: IntlShape,
    consumptions: number[],
    maxConsumptionUnit: PowerUnit,
    dataIndex: number,
) => {
    const portion = Number(value);
    const power = formatPower(portion, intl.locale, maxConsumptionUnit);
    return `<b>${power.kwhLocalString} ${power.unit} (${formatPowerPercentage(portion, consumptions, dataIndex, intl)}%)</b>`;
};

export const generateTooltip = (
    params: Array<CustomItemTooltip>,
    intl: IntlShape,
    yearMonths: Temporal.PlainYearMonth[],
    consumptions: number[],
    maxConsumptionUnit: PowerUnit,
) => {
    const tooltipElements: Array<CustomItemTooltip> = params as Array<CustomItemTooltip>;
    const index = tooltipElements[0].dataIndex;
    const formatDate = `${intl.formatDate(yearMonths[index].toPlainDate({ day: 1 }).toString(), {
        month: 'long',
    })} ${yearMonths[index].year}`;

    const tooltipDataToRender = tooltipElements.map(param =>
        Number(param.value) === 0
            ? ''
            : `<p>
                ${param.marker} ${param.seriesName}: ${formatTooltipValue(param.value, intl, consumptions, maxConsumptionUnit, index)}
            </p>`,
    );
    return `<div style="min-width:240px">
                <p>
                    ${formatDate}
                </p>
                ${tooltipDataToRender.join('')}
            </div>`;
};
