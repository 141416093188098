import { DateRange } from '../components/CalendarWrapper';
import { FormatDateOptions, useIntl } from 'react-intl';
import { Period } from './useFilterValues';

export const useFormatDate = (selectedDates: DateRange, period: Period) => {
    const intl = useIntl();

    const dayFormat = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' } as FormatDateOptions;
    if (selectedDates.from.equals(selectedDates.to)) {
        return (
            intl.formatMessage({ id: 'common.on' }) +
            ' ' +
            selectedDates.from.toLocaleString(intl.locale, {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            })
        );
    } else {
        return period.includes('Month')
            ? // e.g. in December 2023
              intl.formatMessage({ id: 'common.in' }) +
                  ' ' +
                  intl.formatMessage({ id: `common.months.${selectedDates.from.month - 1}` }) +
                  ' ' +
                  selectedDates.from.year
            : // e.g. from [day] to [day]
              intl.formatMessage({ id: 'common.from' }) +
                  ' ' +
                  selectedDates.from.toLocaleString(intl.locale, dayFormat) +
                  ' ' +
                  intl.formatMessage({ id: 'common.to' }) +
                  ' ' +
                  selectedDates.to.toLocaleString(intl.locale, dayFormat);
    }
};
