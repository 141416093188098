import { Temporal } from 'temporal-polyfill';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

export const intervals = ['15min', 'hour', 'day', 'week', 'month'] as const;
export const standardPeriodOptions = ['dayBeforeYesterday', 'yesterday', 'thisMonth', 'lastMonth', 'thisYear'] as const;

const defaultFilterValues: FilterValues = {
    period: 'dayBeforeYesterday',
    interval: '15min',
};

const plainDateSchema = z
    .string()
    .refine(x => !Number.isNaN(new Date(x).getTime()))
    .transform(x => Temporal.PlainDate.from(x));

export type FilterValues = z.infer<typeof filterValuesSchema>;
export type Period = FilterValues['period'];
export type Interval = z.infer<typeof intervalSchema>;

const intervalSchema = z.enum(intervals);

const baseFilterValuesSchema = {
    interval: intervalSchema,
    marketLocationId: z.string().optional(),
};

export const filterValuesSchema = z
    .object({
        period: z.enum(standardPeriodOptions),
        ...baseFilterValuesSchema,
    })
    .or(
        z.object({
            period: z.literal('custom'),
            from: plainDateSchema,
            to: plainDateSchema,
            ...baseFilterValuesSchema,
        }),
    );

export function useFilterValues(defaultValues = defaultFilterValues): [FilterValues, (values: FilterValues) => void] {
    const [searchParams, setSearchParams] = useSearchParams();
    const filterValuesParse = useMemo(
        () => filterValuesSchema.safeParse(Object.fromEntries(searchParams.entries())),
        [searchParams],
    );
    const filterValues: FilterValues = filterValuesParse.success
        ? (filterValuesParse.data as FilterValues)
        : defaultValues;

    const setFilterValues = useCallback(
        (values: FilterValues) => {
            setSearchParams(filterToSearchParams(values));
        },
        [setSearchParams],
    );

    return [filterValues, setFilterValues];
}

function filterToSearchParams(filter: FilterValues): URLSearchParams {
    const params = new URLSearchParams();

    if (filter.period === 'custom') {
        params.set('from', filter.from.toString());
        params.set('to', filter.to.toString());
    }

    params.set('period', filter.period);
    params.set('interval', filter.interval);

    if (filter.marketLocationId !== undefined) {
        params.set('marketLocationId', filter.marketLocationId);
    }

    return params;
}
