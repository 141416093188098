import { graphic } from 'echarts';

const powerSupplyTypeToGradientMap: Record<string, string[]> = {
    wind: ['#a8ccfd', '#28cded'],
    solar: ['#edcf00', '#ef913a'],
    futures: ['#1fb274', '#055e45'],
    spot: ['#a6a6a6', '#737373'],
    // total: ['#d2f0e3', '#d2f0e3'],
};

export const getGradientColorByType = (type: string) => {
    return new graphic.LinearGradient(0, 0, 0, 1, [
        {
            offset: 0,
            color: powerSupplyTypeToGradientMap[type][0],
        },
        {
            offset: 1,
            color: powerSupplyTypeToGradientMap[type][1],
        },
    ]);
};
