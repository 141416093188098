import { useMemo, useState } from 'react';
import { KPICard } from '../../kpi-card/KPICard';
import { useIntl } from 'react-intl';
import { ExchangePriceChart } from '../../charts/ExchangePriceChart';
import { YAXisComponentOption, EChartsOption, MarkAreaComponentOption } from 'echarts';
import { Temporal } from 'temporal-polyfill';
import { getPlainDateTimeFromString } from '../../../utils/getPlainDateTimeFromString';
import { PriceItem, PricesType } from '../../../types/prices';
import { EuroIcon } from '@trawa-energy/ui-kit';
import { KPICardFooter } from './KPICardFooter';
import { KPICardHeader } from './KPICardHeader';

type ExchangePriceCardProps = {
    isLoading: boolean;
    prices?: PricesType['data'];
};

export const ExchangePriceCard = ({ prices, isLoading }: ExchangePriceCardProps) => {
    const intl = useIntl();
    const [today] = useState(Temporal.Now.plainDateISO(intl.timeZone!));

    const xAxisData = useMemo(() => {
        if (prices === undefined) {
            return [];
        }
        const filterData = (items: PricesType['data'][0]) => {
            return items.flatMap((item: PriceItem) => {
                if (getPlainDateTimeFromString(item.timestampStart).toString().includes(today.toString())) {
                    return `${intl.formatDate(item.timestampStart, { timeStyle: 'short' })}`;
                }
                return [];
            });
        };

        return [...filterData(prices[0]), ...filterData(prices[1])].sort();
    }, [prices, intl, today]);

    const xAxis = {
        type: 'category',
        axisTick: {
            show: false,
        },
        axisLabel: {
            fontSize: 10,
            interval: (index: number, value: string) => value === '00:00' || value === '12:00' || value === '23:00',
        },
        data: xAxisData,
    } as EChartsOption['xAxis'];

    const yAxis: YAXisComponentOption = {
        type: 'value',
        name: intl.formatMessage({ id: 'exchangePrices.yAxisLabel' }),
        nameGap: 20,
        nameTextStyle: {
            fontSize: 10,
            fontWeight: 'bold',
            color: 'black',
        },
        axisLabel: {
            fontSize: 10,
        },
    };

    const title = intl.formatMessage({ id: 'kpi.exchangePrice.title' });
    const subtitle = prices
        ? intl.formatMessage(
              { id: 'kpi.exchangePrice.subtitle' },
              {
                  date: intl.formatDate(today.toString(), {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                  }),
              },
          )
        : intl.formatMessage({ id: 'kpi.exchangePrice.noDataAvailableSubtitle' });

    const name = 'kpi-exchange-prices';

    const markLineData = [
        {
            xAxis: Temporal.Now.plainTimeISO().hour,
            label: {
                formatter: intl.formatMessage({ id: 'exchangePrices.now' }),
            },
        },
    ];

    const markArea: MarkAreaComponentOption = {
        itemStyle: {
            color: '#F3F3F3',
            opacity: 0.5,
        },
        emphasis: {
            itemStyle: {
                color: '#F3F3F3',
            },
        },
        data: [
            [
                {
                    xAxis: 0,
                },
                {
                    xAxis: Temporal.Now.plainTimeISO().hour,
                },
            ],
        ],
    };

    return (
        <KPICard.Root name={name} isLoading={isLoading}>
            <KPICard.Header testId={name}>
                <KPICardHeader title={title} subtitle={subtitle} Icon={EuroIcon} />
            </KPICard.Header>
            <KPICard.Content name={name} showEmpty={!prices}>
                <ExchangePriceChart
                    prices={prices}
                    height={'230px'}
                    yAxis={yAxis}
                    xAxis={xAxis}
                    isLoading={isLoading}
                    markArea={markArea}
                    markLineData={markLineData}
                />
            </KPICard.Content>
            <KPICard.Footer name={name}>
                <KPICardFooter text={intl.formatMessage({ id: 'kpi.exchangePrice.linkText' })} url="/exchange-prices" />
            </KPICard.Footer>
        </KPICard.Root>
    );
};
