import { createContext } from 'react';
import { EChartsType } from 'echarts';

interface EChartContextProps {
    chart?: EChartsType;

    setChart?(chart: EChartsType): void;
}

export const EChartFuturePricesContext = createContext<EChartContextProps>({
    chart: undefined,
    setChart: undefined,
});
