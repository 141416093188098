import React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { Content } from './Content';
import { LoadingSpinner } from '@trawa-energy/ui-kit';

type KPICardProps = {
    name?: string;
    children?: React.ReactNode;
    isLoading?: boolean;
};

const KPICard = ({ children, name = '', isLoading }: KPICardProps) => {
    if (isLoading) {
        return (
            <div
                className={
                    'w-full flex justify-center items-center min-h-[357px] md:w-1/2 h-auto border border-muted-foreground rounded-xl bg-card p-4'
                }
            >
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div className={'w-full md:w-1/2 h-auto border border-muted-foreground rounded-xl bg-card min-h-250 p-4'}>
            {children}
        </div>
    );
};

KPICard.Header = Header;
KPICard.Footer = Footer;
KPICard.Content = Content;
KPICard.Root = KPICard;

export { KPICard };
