import { Temporal } from 'temporal-polyfill';

// when there are at least 3 months consumption data, display the last 3 - 6 months
// when there are less than 3 months consumption data, display the data and fill the rest with 0
export const calDisplayMonths = (today: Temporal.PlainDate, yearMonths: Temporal.PlainYearMonth[]) => {
    const currentYearMonth = today.toPlainYearMonth();
    if (yearMonths.length < 2) {
        return [currentYearMonth, currentYearMonth.add({ months: 1 }), currentYearMonth.add({ months: 2 })];
    }

    if (yearMonths.length === 2) {
        return [currentYearMonth.subtract({ months: 1 }), currentYearMonth, currentYearMonth.add({ months: 1 })];
    }
    return yearMonths;
};

export const calDisplayConsumptions = (consumptions: number[]) => {
    return consumptions.length >= 3
        ? consumptions.slice(-6)
        : consumptions.concat(new Array(3 - consumptions.length).fill(0));
};

export const searchTotalConsumptionByMonth = (
    totalConsumptions: { yearMonth: Temporal.PlainYearMonth; kwh: number }[],
    searchMonth: string,
) => {
    return totalConsumptions.find(e => e.yearMonth.toString() === searchMonth);
};
