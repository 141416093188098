import { KPICard } from '../../kpi-card/KPICard';
import { useIntl } from 'react-intl';
import { ChartLineIcon } from '@trawa-energy/ui-kit';
import { ConsumptionChart } from '../../charts/ConsumptionChart';
import { getMaxPowerUnit } from '../../../utils/formatting/formatPowerConsumption';
import { Temporal } from 'temporal-polyfill';
import { KPICardFooter } from './KPICardFooter';
import { KPICardHeader } from './KPICardHeader';
import { useMemo } from 'react';
import { useGetTotalConsumptionInfo } from '../../../hooks/requests';
import { useConsumptionChartData, productTypes } from '../../../hooks/useConsumptionChartData.ts';
import { BarSeriesOption, EChartsOption } from 'echarts';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags.ts';
import { calDisplayMonths, calDisplayConsumptions } from '../../../utils/charts/calDisplayConsumptionMonths.ts';
import { CustomItemTooltip } from '../../../types/chart.ts';
import { generateTooltip } from '../../../utils/charts/generateTooltip.ts';

const name = 'kpi-consumption';

export const ConsumptionCard = () => {
    const intl = useIntl();
    const { isSupplyByProduct } = useFeatureFlags();
    const today = useMemo(() => Temporal.Now.plainDateISO(intl.timeZone!), [intl.timeZone]);
    const {
        totalConsumptions,
        averageConsumption,
        firstIngestionDate,
        isLoading: isLoadingConsumptions,
        title,
        subtitle,
    } = useGetTotalConsumptionInfo();
    const {
        yearMonths,
        consumptions,
        chartData,
        isLoading: isLoadingConsumptionChartData,
    } = useConsumptionChartData({
        today,
        interval: 'month',
    });

    const isLoading = isLoadingConsumptionChartData || isLoadingConsumptions;
    const isTotalConsumptionDataReady = !!(totalConsumptions && averageConsumption && firstIngestionDate);
    const maxConsumptionUnit = getMaxPowerUnit(consumptions, intl.locale);

    const seriesData: BarSeriesOption[] = isSupplyByProduct
        ? chartData.map(({ color, data, productType }) => {
              return {
                  name: intl.formatMessage({ id: `overview.powerSupplier-${productType}` }),
                  type: 'bar',
                  stack: 'consumption',
                  data: calDisplayConsumptions(data),
                  barWidth: '35%',
                  barMaxWidth: '26px',
                  barMinWidth: '17px',
                  itemStyle: {
                      color,
                  },
              };
          })
        : [
              {
                  name: intl.formatMessage({ id: 'exchangePrices.price' }),
                  type: 'bar',
                  data: calDisplayConsumptions(consumptions),
                  barWidth: '35%',
                  barMaxWidth: '26px',
                  barMinWidth: '17px',
                  itemStyle: {
                      color: '#0558c7ff',
                  },
              },
          ];

    const tooltip = isSupplyByProduct
        ? {
              trigger: 'axis',
              axisPointer: {
                  type: 'shadow',
              },
              formatter: (params: Array<CustomItemTooltip>) =>
                  generateTooltip(params, intl, yearMonths, consumptions, maxConsumptionUnit),
          }
        : undefined;
    return (
        <KPICard.Root name={name} isLoading={isLoading}>
            <KPICard.Header testId={name}>
                {title && subtitle && <KPICardHeader title={title} subtitle={subtitle} Icon={ChartLineIcon} />}
            </KPICard.Header>

            <KPICard.Content name={name} showEmpty={!consumptions.length || !isTotalConsumptionDataReady}>
                <ConsumptionChart
                    height={'230px'}
                    isLoading={isLoading}
                    maxConsumptionUnit={maxConsumptionUnit}
                    series={seriesData}
                    xAxisData={calDisplayMonths(today, yearMonths).map(yearMonth =>
                        intl.formatDate(yearMonth.toPlainDate({ day: 1 }).toString(), {
                            month: 'short',
                        }),
                    )}
                    tooltip={tooltip as EChartsOption['tooltip']}
                />
            </KPICard.Content>

            <KPICard.Footer name={name}>
                <KPICardFooter text={intl.formatMessage({ id: 'kpi.consumption.linkText' })} url="/consumption" />
            </KPICard.Footer>
        </KPICard.Root>
    );
};
