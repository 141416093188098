import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FilterValues, standardPeriodOptions } from '../../hooks/useFilterValues';
import { CustomPeriodPopover } from '../CustomPeriodPopover';
import { getSelectedDatesByFilterValues } from '../../utils/getSelectedDatesByFilterValues';
import { Temporal } from 'temporal-polyfill';

export type CustomPeriodPopoverProps = {
    period: string;
    from: Temporal.PlainDate;
    to: Temporal.PlainDate;
};

type Props = {
    filterValues: FilterValues;
    setFilterValues: (values: FilterValues) => void;
    isConsumptionView?: boolean;
    onValueChange?: (period?: FilterValues['period']) => void;
};

export const DateRangeFilterControls: FC<Props> = ({
    filterValues,
    setFilterValues,
    isConsumptionView,
    onValueChange,
}) => {
    const intl = useIntl();
    const selectedDates = useMemo(() => getSelectedDatesByFilterValues(filterValues), [filterValues]);

    const periodTitle = (period: string) => {
        if (period === filterValues.period) {
            return (
                selectedDates.from.toLocaleString(intl.locale) +
                (selectedDates.from === selectedDates.to ? '' : '-' + selectedDates.to.toLocaleString(intl.locale))
            );
        }

        return '';
    };

    const filterOptions = isConsumptionView
        ? standardPeriodOptions.filter(p => p !== 'thisYear')
        : standardPeriodOptions;

    return (
        <div>
            <div className="font-bold text-sm 2xl:text-base pb-1 2xl:pb-0">
                {intl.formatMessage({ id: 'filterControls.periods.label' })}
            </div>
            <div className="flex flex-wrap gap-2 mb-2 md:gap-0 md:flex-nowrap items-center md:border border-gray-2 rounded-xl overflow-hidden ">
                {filterOptions.map((period, i) => (
                    <div
                        key={period}
                        className={`font-bold flex flex-nowrap items-center self-stretch rounded-xl md:rounded-none border md:border-0 md:border-r border-gray-2 hover:text-primary text-sm 2xl:text-base ${
                            period === filterValues.period
                                ? 'bg-primary-2'
                                : 'bg-white text-gray-4 hover:bg-primary-blue-1'
                        }`}
                    >
                        <button
                            name={intl.formatMessage({ id: `filterControls.periods.${period}` })}
                            type="button"
                            aria-pressed={period === filterValues.period}
                            className="font-bold p-2 2xl:px-4 2xl:py-3"
                            title={periodTitle(period)}
                            onClick={() => {
                                setFilterValues({ ...filterValues, period });

                                onValueChange?.(period as FilterValues['period']);
                            }}
                        >
                            <span>{intl.formatMessage({ id: `filterControls.periods.${period}` })}</span>
                        </button>
                    </div>
                ))}
                <CustomPeriodPopover
                    selectedDates={selectedDates}
                    isSelected={filterValues.period === 'custom'}
                    onChange={dateRange => {
                        setFilterValues({
                            ...filterValues,
                            period: 'custom',
                            from: Temporal.PlainDate.from(dateRange.from),
                            to: Temporal.PlainDate.from(dateRange.to),
                        });

                        onValueChange?.('custom');
                    }}
                />
            </div>
        </div>
    );
};
