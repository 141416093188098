import { Temporal } from 'temporal-polyfill';
import { FilterValues } from '../hooks/useFilterValues';
import { getDateRangeForPeriod } from './getDateRangeForPeriod';

export const getSelectedDatesByFilterValues = (filterValues: FilterValues) => {
    if (filterValues.period === 'custom') {
        return { from: filterValues.from, to: filterValues.to, exclusiveTo: filterValues.to.add({ days: 1 }) };
    }

    const today = Temporal.Now.plainDateISO();
    const range = getDateRangeForPeriod(today, filterValues.period);

    return { from: range.from, to: range.to, exclusiveTo: range.to.add({ days: 1 }) };
};
