import { FilterValues } from './useFilterValues.tsx';
import { useEffect, useMemo, useState } from 'react';
import { useContractPartnerId } from '../userSettings.ts';
import { usePrevious } from './usePrevious.ts';
import { trpc } from '../utils/trpc.ts';
import { getSelectedDatesByFilterValues } from '../utils/getSelectedDatesByFilterValues.ts';
import { useFormatDate } from './useFormatDate.ts';
import { MarketLocation } from '../types/locations.ts';

type MarketLocationTypeDropdown = {
    id: string;
    label: string;
};

const MIN_MAX_EXTRA_MARGIN = 1.1; /* add 10% */

const calculateMinMax = (range: { min: number; max: number }) => {
    /* we set min and max to the absolute maximum value (positive or negative) to have all axis centered on 0 */
    const maximum = Math.max(Math.abs(range.max), Math.abs(range.min));
    return {
        max: maximum * MIN_MAX_EXTRA_MARGIN,
        min: -1 * maximum * MIN_MAX_EXTRA_MARGIN,
    };
};

const filterLocationDataForSelect = (data: MarketLocation[]) => {
    return data
        .filter(i => i.meteringType !== 'SLP')
        .map(({ id, label }) => ({
            id,
            label: label ? `${label} (${id})` : id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
};

export const useAnalysisChart = (filterValues: FilterValues, setFilterValues: (filterValues: FilterValues) => void) => {
    const contractPartnerId = useContractPartnerId();
    const previousContractPartnerId = usePrevious(contractPartnerId);
    const selectedDates = useMemo(() => getSelectedDatesByFilterValues(filterValues), [filterValues]);
    const formatedDate = useFormatDate(selectedDates, filterValues.period);
    const [marketLocationDropdownOptions, setMarketLocationDropdownOptions] = useState<MarketLocationTypeDropdown[]>(
        [],
    );

    // MarketLocations List Dropdown
    const {
        data: marketLocationsData,
        isLoading: isLoadingMarketLocationData,
        refetch: refetchMarketLocations,
    } = trpc.dashboard.getMarketLocations.useQuery({
        contractPartnerId,
    });

    const [activeMarketLocationId, setActiveMarketLocationId] = useState(
        filterValues.marketLocationId ? filterValues.marketLocationId : marketLocationsData?.[0]?.id,
    );

    // MarketLocation Data
    const { data: analysisElementData, isLoading } = trpc.analysis.getAnalysisDataByMarketId.useQuery(
        {
            contractPartnerId,
            marketLocationId: activeMarketLocationId,
            dateRange: {
                from: selectedDates.from.toString(),
                exclusiveTo: selectedDates.exclusiveTo.toString(),
            },
            aggregationInterval: filterValues.interval === '15min' ? undefined : filterValues.interval,
        },
        { enabled: !!activeMarketLocationId, staleTime: 1000 * 60 * 5 },
    );

    // Prices Data
    const { data: pricesData } = trpc.prices.getPricesByRange.useQuery({
        dateRange: {
            from: selectedDates.from.toString(),
            exclusiveTo: selectedDates.exclusiveTo.toString(),
        },
        aggregationInterval: filterValues.interval === '15min' ? undefined : filterValues.interval,
    });

    /* formatting dropdown options */
    useEffect(() => {
        if (marketLocationsData) {
            setMarketLocationDropdownOptions(filterLocationDataForSelect(marketLocationsData));
        }
    }, [marketLocationsData, marketLocationDropdownOptions.length]);

    /* Set active marketLocationId */
    useEffect(() => {
        if (marketLocationsData) {
            const selectedMarketLocationId = filterValues.marketLocationId || marketLocationsData?.[0]?.id;
            const marketLocationDropdownItemId = marketLocationsData.find(({ id }) => id === selectedMarketLocationId)
                ? selectedMarketLocationId
                : marketLocationsData[0].id;

            if (marketLocationDropdownItemId !== selectedMarketLocationId) {
                setFilterValues({
                    ...filterValues,
                    marketLocationId: marketLocationDropdownItemId,
                });
            }

            setActiveMarketLocationId(selectedMarketLocationId);
        }
    }, [marketLocationsData, setActiveMarketLocationId, setFilterValues, filterValues]);

    /* Refetch data when contract partner changes */
    /* or */
    /* Update active marketLocation with filter values */
    useEffect(() => {
        if (previousContractPartnerId !== contractPartnerId) {
            refetchMarketLocations();
            setFilterValues({
                ...filterValues,
                marketLocationId: marketLocationDropdownOptions?.[0]?.id,
            });
        }
    }, [
        previousContractPartnerId,
        contractPartnerId,
        marketLocationDropdownOptions,
        filterValues,
        setFilterValues,
        refetchMarketLocations,
    ]);

    const selectedMarketLocation = useMemo(
        () =>
            analysisElementData?.marketLocations?.find(({ id }) => filterValues.marketLocationId === id) ??
            analysisElementData?.marketLocations[0],
        [analysisElementData, filterValues],
    );

    const isPVGenerationAvailable = !!selectedMarketLocation?.pvData;
    const isConsumptionPeakAvailable =
        !!selectedMarketLocation?.marketLocationPeak && filterValues.interval === '15min';

    const consumption = selectedMarketLocation?.volumeEntriesByMarketLocation ?? [];
    const consumptionLength = consumption.length > 0 ? consumption?.length : 0;
    const pvGeneration = selectedMarketLocation?.pvData?.slice(0, consumptionLength) ?? [];
    const energyCosts = selectedMarketLocation?.cost?.slice(0, consumptionLength) ?? [];

    const consumptionPeak = selectedMarketLocation?.marketLocationPeak?.kwh ?? 0;
    const maxConsumptionInPeriod = selectedMarketLocation
        ? Math.max(...(selectedMarketLocation.volumeEntriesByMarketLocation ?? []).map(o => o.kwh ?? 0))
        : 0;
    const maxPVInPeriod = selectedMarketLocation?.pvData
        ? Math.max(...(selectedMarketLocation.pvData ?? []).map(o => o.kwh ?? 0))
        : 0;
    const highPointChart = Math.ceil(Math.max(maxConsumptionInPeriod, maxPVInPeriod, consumptionPeak) * 1.05);

    const prices = pricesData?.slice(0, consumptionLength) ?? [];

    /* get Ranges */
    const rangePrices = prices
        ?.map(({ price }) => Number(price))
        .reduce(
            (acc, price) => ({
                min: Math.min(acc.min, price * 100),
                max: Math.max(acc.max, price * 100),
            }),
            { min: Infinity, max: -Infinity },
        );
    const rangeCosts = energyCosts
        ?.map(({ sumCost }) => Number(sumCost))
        .reduce(
            (acc, cost) => ({
                min: Math.min(acc.min, cost),
                max: Math.max(acc.max, cost),
            }),
            { min: Infinity, max: -Infinity },
        );

    const hasNegativeValue = rangePrices.min < 0 || rangeCosts.min < 0;

    return {
        consumption,
        isLoadingAnalysisData: isLoading,
        isLoadingMarketLocationData,
        activeMarketLocationId,
        marketLocationDropdownOptions,
        pvGeneration,
        prices,
        energyCosts,
        highPointChart,
        consumptionPeak,
        consumptionPeakIsSet: isConsumptionPeakAvailable,
        pvGenerationIsSet: isPVGenerationAvailable,
        energyCostsIsSet: energyCosts ? energyCosts.length > 0 : false,
        selectedMarketLocation,
        formatedDate,
        yAxisPricesMinMaxOptions: !hasNegativeValue ? {} : calculateMinMax(rangePrices),
        yAxisCostsMinMaxOptions: !hasNegativeValue ? {} : calculateMinMax(rangeCosts),
    };
};
