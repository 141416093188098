import { ConsumptionCard } from './ConsumptionCard';
import { ExchangePriceCard } from './ExchangePriceCard';
import { trpc } from '../../../utils/trpc.ts';

export const KPICardsContainer = () => {
    const { data: prices, isLoading: isLoadingExchangePriceChart } = trpc.prices.getAheadPrices.useQuery({});
    return (
        <div className="flex flex-col md:flex-row w-full gap-4 mb-8">
            <ConsumptionCard />
            <ExchangePriceCard prices={prices?.data} isLoading={isLoadingExchangePriceChart} />
        </div>
    );
};
