import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { trpc } from '../utils/trpc.ts';
import { MapWithMarkers } from '../components/maps/MapWithMarkers.tsx';
import { formatMapCoordinates } from '../components/maps/formatMapCoordinates.ts';
import { useGetMarketLocations } from '../hooks/requests';
import { PageHeading } from '../components/PageHeading.tsx';

export function Locations() {
    const intl = useIntl();
    const { data: account } = trpc.account.getCurrent.useQuery({});
    const { data: assetLocations } = trpc.assets.getAssets.useQuery({});
    const { data: flexPowerLocations } = trpc.assets.getFlexPowerLocations.useQuery({});
    const { data: marketLocations } = useGetMarketLocations();

    const locations = useMemo(() => {
        if (!marketLocations || !assetLocations || !flexPowerLocations) {
            return [];
        }
        return formatMapCoordinates(marketLocations, assetLocations, flexPowerLocations);
    }, [marketLocations, assetLocations, flexPowerLocations]);

    return (
        <section className="h-full flex flex-col">
            <PageHeading title={'locations.title'} />
            <div className="bg-gray-2 w-full h-full rounded-xl overflow-hidden">
                <MapWithMarkers setsOfLocations={locations} />
            </div>
        </section>
    );
}
