import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DropdownField } from '@trawa-energy/ui-kit';
import { FilterValues, Interval } from '../../../hooks/useFilterValues.tsx';
import { getDateRangeForPeriod } from '../../../utils/getDateRangeForPeriod.ts';
import { Temporal } from 'temporal-polyfill';

type Props = {
    filterValues: FilterValues;
    setFilterValues: (values: FilterValues) => void;
    showDayOption?: boolean;
    onValueChange?: (interval?: Interval) => void;
};

const intervalOptionsConfigObj = (range: number): Record<string, Interval[]> => ({
    dayBeforeYesterday: ['15min', 'hour'],
    yesterday: ['15min', 'hour'],
    thisMonth: range < 8 ? ['15min', 'hour', 'day'] : ['hour', 'day', 'week'],
    lastMonth: ['hour', 'day', 'week'],
    thisYear: ['day', 'week', 'month'],
});

const getCustomRangeIntervals = (range: number) => {
    switch (true) {
        case range < 1:
            return ['15min', 'hour'];
        case range < 2:
            return ['15min', 'hour', 'day'];
        case range < 8:
            return ['hour', 'day'];
        case range < 30:
            return ['hour', 'day', 'week'];
        case range < 60:
            return ['day', 'week'];
        default:
            return ['day', 'week', 'month'];
    }
};

export const IntervalFilterControls: FC<Props> = ({
    filterValues,
    setFilterValues,
    showDayOption = true,
    onValueChange,
}) => {
    const intl = useIntl();
    const [intervalOptions, setIntervalOptions] = useState<Interval[]>([]);

    useEffect(() => {
        const today = Temporal.Now.plainDateISO();
        const isCustom = filterValues.period === 'custom';
        const range = isCustom ? filterValues : getDateRangeForPeriod(today, filterValues.period);
        const totalDays = range.from.until(range.to).days;

        const intervalOptionsForPeriod = isCustom
            ? getCustomRangeIntervals(totalDays || 0)
            : intervalOptionsConfigObj(totalDays as number)[filterValues.period];
        setIntervalOptions(intervalOptionsForPeriod as Interval[]);
    }, [filterValues, setFilterValues, showDayOption]);

    useEffect(() => {
        setFilterValues({
            ...filterValues,
            interval: !intervalOptions.includes(filterValues.interval)
                ? (intervalOptions[intervalOptions.length - 1] as Interval)
                : filterValues.interval,
        });
    }, [filterValues, intervalOptions, setFilterValues]);

    return (
        <div className="w-full md:max-w-52">
            <DropdownField
                sortOptions={false}
                label={intl.formatMessage({ id: 'filterControls.intervals.label' })}
                lean
                options={intervalOptions.map(option => ({
                    text: intl.formatMessage({ id: 'filterControls.intervals.' + option }),
                    value: option,
                }))}
                onChange={option => {
                    if (option?.value === undefined) {
                        return;
                    }

                    setFilterValues({
                        ...filterValues,
                        interval: option.value,
                    });

                    onValueChange?.(option.value);
                }}
                value={filterValues.interval}
            />
        </div>
    );
};
