import { useContractPartnerId } from '../../userSettings.ts';
import { trpc } from '../../utils/trpc.ts';
import { Temporal } from 'temporal-polyfill';
import { getPlainDateTimeFromString } from '../../utils/getPlainDateTimeFromString.ts';
import { Interval } from '../useFilterValues.tsx';
import { useFeatureFlags } from '../useFeatureFlags.ts';

export const dateRangeLastNMonths = (n: number, today: Temporal.PlainDate) => {
    const startDate = today.subtract({ months: n - 1 });
    return {
        from: Temporal.PlainDate.from({ year: startDate.year, month: startDate.month, day: 1 }).toString(),
        exclusiveTo: today.add({ days: 1 }).toString(),
    };
};

type GetTotalConsumptionsByIntervalProps = {
    interval?: Interval;
    today: Temporal.PlainDate;
};

export type GetSupplyByProductTypeProps = {
    interval?: 'day' | 'month';
    today: Temporal.PlainDate;
};

export const useTotalConsumptionsByInterval = ({ today, interval = 'month' }: GetTotalConsumptionsByIntervalProps) => {
    const contractPartnerId = useContractPartnerId();

    const { data: volumeEntries, isLoading } = trpc.dashboard.getVolumeEntries.useQuery({
        dateRange: dateRangeLastNMonths(6, today),
        contractPartnerId,
        aggregationInterval: interval === '15min' ? undefined : interval,
    });

    if (!volumeEntries) {
        return { totalConsumptionsByInterval: [] };
    }

    const volumeEntriesGroupedByMonth = Object.groupBy(volumeEntries, volumeEntry =>
        getPlainDateTimeFromString(volumeEntry.timestampStart).toPlainYearMonth().toString(),
    );

    const yearMonths = Object.keys(volumeEntriesGroupedByMonth);

    const totalConsumptions = yearMonths.map(yearMonth => ({
        yearMonth: Temporal.PlainYearMonth.from(yearMonth),
        kwh: volumeEntriesGroupedByMonth[yearMonth]!.reduce((acc, currentValue) => acc + currentValue.kwh, 0),
    }));

    return {
        isLoading,
        totalConsumptionsByInterval: totalConsumptions,
    };
};

export const useGetSupplyByProductType = ({ today, interval = 'month' }: GetSupplyByProductTypeProps) => {
    const { isSupplyByProduct } = useFeatureFlags();
    const contractPartnerId = useContractPartnerId();

    const { data, isLoading } = trpc.dashboard.getSupplyProductsByInterval.useQuery(
        {
            dateRange: dateRangeLastNMonths(6, today),
            contractPartnerId,
            aggregationInterval: interval,
        },
        {
            enabled: isSupplyByProduct,
        },
    );
    return { data: data ?? [], isLoading };
};
