import {
    useTotalConsumptionsByInterval,
    GetSupplyByProductTypeProps,
    useGetSupplyByProductType,
} from '../hooks/requests';
import { SupplyByInterval, SolidSupplyProductValues } from '../types/consumptions';
import { Temporal } from 'temporal-polyfill';
import { calConsumptionsByProduct } from '../utils/charts/calConsumptionsByProduct';
import { sumSupplyByProductTypes } from '../utils/charts/sumSupplyByProductTypes';
import { searchTotalConsumptionByMonth } from '../utils/charts/calDisplayConsumptionMonths';
import { getGradientColorByType } from '../utils/charts/getGradientColorByType';

export const productTypes = ['wind', 'solar', 'futures', 'spot'];

type ConsumptionData = {
    yearMonth: Temporal.PlainYearMonth;
    totalKWh: number;
    consumptionsByProduct: { kwh: number; type: string }[];
}[];

const calConsumptionData = (
    suppliesByInterval: SupplyByInterval[],
    totalConsumptionsByMonth: { yearMonth: Temporal.PlainYearMonth; kwh: number }[],
) => {
    const consumptionData = suppliesByInterval
        .filter(supplyByInterval => searchTotalConsumptionByMonth(totalConsumptionsByMonth, supplyByInterval.id)?.kwh)
        .map(supplyByInterval => {
            const yearMonth = Temporal.PlainYearMonth.from(supplyByInterval.id);
            const totalKWh = searchTotalConsumptionByMonth(totalConsumptionsByMonth, supplyByInterval.id)!.kwh;
            const consumptionsByProduct = calConsumptionsByProduct(supplyByInterval.products);
            const solidConsumptionSum = sumSupplyByProductTypes(consumptionsByProduct);
            const totalConsumptionWithoutSpot = totalKWh - sumSupplyByProductTypes(consumptionsByProduct, ['spot']);

            consumptionsByProduct
                .filter(e => Object.values(SolidSupplyProductValues).includes(e.type))
                .forEach(e => {
                    e.kwh = (e.kwh * totalConsumptionWithoutSpot) / solidConsumptionSum;
                });

            return {
                yearMonth,
                totalKWh,
                consumptionsByProduct,
            };
        });

    const supplyUnknownConsumptions = totalConsumptionsByMonth
        .slice(suppliesByInterval?.length)
        .map(e => ({ yearMonth: e.yearMonth, totalKWh: e.kwh, consumptionsByProduct: [{ kwh: e.kwh, type: 'spot' }] }));

    consumptionData.push(...supplyUnknownConsumptions);
    return consumptionData;
};

const preProcesConsumptionByInterval = (consumptionsByInterval: ConsumptionData) => {
    return consumptionsByInterval.flatMap(consumptionByInterval => ({
        ...consumptionByInterval,
        consumptionsByProduct: productTypes.map(
            productType =>
                consumptionByInterval.consumptionsByProduct.find(e => e.type === productType) || {
                    type: productType,
                    kwh: 0,
                },
        ),
    }));
};

const calChartData = (consumptionsByInterval: ConsumptionData) => {
    const d = preProcesConsumptionByInterval(consumptionsByInterval).flatMap(e =>
        e.consumptionsByProduct.map(x => ({ productType: x.type, kwh: x.kwh, yearMonth: e.yearMonth })),
    );

    return productTypes.map(productType => ({
        productType,
        data: d
            .filter(e => e.productType === productType)
            .sort((a, b) => Temporal.PlainYearMonth.compare(a.yearMonth, b.yearMonth))
            .map(e => e.kwh),
        color: getGradientColorByType(productType),
    }));
};

export const useConsumptionChartData = ({ today, interval = 'month' }: GetSupplyByProductTypeProps) => {
    const { totalConsumptionsByInterval: totalConsumptionsByMonth, isLoading: isLoadingTotalConsumptionsByMonth } =
        useTotalConsumptionsByInterval({
            today,
            interval,
        });
    const { data: supplyProducts, isLoading: isLoadingSupplyProducts } = useGetSupplyByProductType({ today, interval });
    const consumptionData = calConsumptionData(supplyProducts, totalConsumptionsByMonth);
    const chartData = calChartData(consumptionData);

    return {
        yearMonths: totalConsumptionsByMonth.map(e => e.yearMonth),
        consumptions: totalConsumptionsByMonth.map(e => e.kwh),
        chartData,
        isLoading: isLoadingTotalConsumptionsByMonth || isLoadingSupplyProducts,
    };
};
