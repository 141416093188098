import { FormatDateOptions, useIntl } from 'react-intl';
import { Temporal } from 'temporal-polyfill';
import { InfoIcon } from '@trawa-energy/ui-kit';
import { ECharts, graphic } from 'echarts';
import { EChart } from './EChart.tsx';
import { Card } from './Card.tsx';
import { PageHeading } from './PageHeading.tsx';
import { FilterValues, useFilterValues } from '../hooks/useFilterValues.tsx';
import { DateRangeFilterControls } from './filter-controls/DateRangeFilterCotrols.tsx';
import { MarketLocationFilterControls } from './filter-controls/MarketLocationFilterControls.tsx';
import { IntervalFilterControls } from './filter-controls/IntervalFilterControls/IntervalFilterControls.tsx';
import { formatAxisDateTimeByInterval } from '../utils/formatting/formatAxisDateTimeInterval.ts';
import { formatValue } from '../utils/formatting/formatValueToFloat.ts';
import { EChartAnalysisContext } from '../context/EChartAnalysisContext.tsx';
import { useAnalysisChart } from '../hooks/useAnalysisChart.ts';
import { useEffect, useState } from 'react';
import { analytics, EventName } from '../analytics';
import { useFeatureFlags } from '../hooks/useFeatureFlags.ts';
import { useAnalysisChartSubtitle } from '../hooks/useAnalysisChartSubtitle.ts';
import { CustomItemTooltip } from '../types/chart.ts';

type EChartParams = {
    selected: Record<string, boolean>;
};

export const AnalysisCharts = () => {
    const intl = useIntl();
    const [filterValues, setFilterValues] = useFilterValues();
    const [chart, setChart] = useState<ECharts | undefined>();
    const { isManageOnly, isSoonToSupply } = useFeatureFlags();
    const limitedChartOptions = isManageOnly || isSoonToSupply;
    const { costSubtitle, consumptionSubtitle } = useAnalysisChartSubtitle({ setFilterValues, filterValues });

    const {
        marketLocationDropdownOptions,
        isLoadingMarketLocationData,
        isLoadingAnalysisData,
        consumption,
        pvGeneration,
        prices,
        energyCosts,
        highPointChart,
        consumptionPeak,
        consumptionPeakIsSet,
        pvGenerationIsSet,
        energyCostsIsSet,
        selectedMarketLocation,
        formatedDate,
        yAxisPricesMinMaxOptions,
        yAxisCostsMinMaxOptions,
    } = useAnalysisChart(filterValues, setFilterValues);

    const paddingX = 50;
    const chartHeight = 250;
    const paddingTop = 100;
    const chartSize = 400 * 2;
    const graphLegendTopPadding = 50;
    const gapSubtitle = costSubtitle ? 0 : 20;

    const isLoadingMarketLocationsTxt = [
        {
            id: 'loading',
            label: intl.formatMessage({ id: 'common.loading' }),
        },
    ];

    const formatTooltipValue = (value: string | number, name: string) => {
        const formatValueMap: Record<string, string> = {
            Netzbezug: 'common.kwh',
            'PV Erzeugung': 'common.kwh',
            'Preis Strombörse': 'common.ctPerKwh',
            Gesamtkosten: 'common.eur',
        };

        return `<b>${formatValue(value, intl)} ${intl.formatMessage({ id: formatValueMap[name] ?? 'common.kwh' })}</b>`;
    };

    // eslint-disable-next-line
    const generateTooltip = (params: Array<CustomItemTooltip> | Object) => {
        const tooltipElements: Array<CustomItemTooltip> = params as Array<CustomItemTooltip>;
        const first: CustomItemTooltip = tooltipElements[0];

        const date = consumption[first.dataIndex].timestampStart;
        let formatOptions: FormatDateOptions = {
            weekday: 'long',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: selectedMarketLocation?.timeZone,
        };

        if (['day'].includes(filterValues.interval)) {
            const { hour, minute, ...rest } = formatOptions;
            formatOptions = rest;
        }

        const formatDate = intl.formatDate(new Date(Temporal.Instant.from(date).toString()), formatOptions);

        const tooltipDataToRender = tooltipElements
            .sort((n1, n2) => n1.seriesIndex - n2.seriesIndex)
            .map(
                param =>
                    `<p>
                    ${param.marker} ${param.seriesName}: ${formatTooltipValue(param.value, param.seriesName)}
                </p>`,
            );

        return `<div style="min-width:240px">
                    <p>
                        ${formatDate}
                    </p>
                    ${tooltipDataToRender.join('')}
                </div>`;
    };

    useEffect(() => {
        // toggle yAxis on legend change
        chart?.on('legendselectchanged', function (params) {
            const { selected } = params as EChartParams;

            analytics.track(EventName.AnalysisToggleChart, {
                'Legend option chosen': selected,
            });

            chart?.setOption({
                yAxis: [
                    { id: 'usage_axis', show: selected.Verbrauch },
                    { id: 'day_ahead_price_axis', show: selected['Preis Strombörse'] },
                    { id: 'total_cost_axis', show: selected.Gesamtkosten },
                ],
            });
        });
    }, [chart]);

    const graphTopTitle = intl.formatMessage(
        {
            id: pvGenerationIsSet
                ? 'analysis.chartTitle.consumptionAndGenerationNoMaLo'
                : 'analysis.chartTitle.consumptionNoMaLo',
        },
        {
            date: formatedDate,
            marketLocation: selectedMarketLocation?.label,
        },
    );

    const marketLocationsDropdownOptions =
        marketLocationDropdownOptions?.length > 0
            ? marketLocationDropdownOptions?.map(({ id, label }) => ({
                  id,
                  label,
              }))
            : isLoadingMarketLocationsTxt;

    const mixpanelEventDateRange = (period?: FilterValues['period']) => {
        analytics.track(EventName.AnalysisSelectTimePeriod, {
            'Time period': period || '',
        });
    };

    const mixpanelEventMarketLocation = (marketLocationId?: string) => {
        analytics.track(EventName.AnalysisSelectLocation, {
            'Market location': marketLocationId || '',
        });
    };

    const mixpanelEventInterval = (interval?: FilterValues['interval']) => {
        analytics.track(EventName.AnalysisSelectTimeInterval, {
            'Time interval': interval || '',
        });
    };

    return (
        <EChartAnalysisContext.Provider value={{ chart, setChart }}>
            <section>
                <div id="analysis-page-header" className="analysis-page-header pb-5">
                    <PageHeading title={'analysis.title'} />
                    <div className="flex flex-wrap items-start gap-x-2">
                        <DateRangeFilterControls
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            onValueChange={mixpanelEventDateRange}
                        />
                        <MarketLocationFilterControls
                            setFilterValues={setFilterValues}
                            filterValues={filterValues}
                            isLoading={isLoadingMarketLocationData}
                            onValueChange={mixpanelEventMarketLocation}
                            marketLocations={marketLocationsDropdownOptions}
                        />
                        <IntervalFilterControls
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            showDayOption={false}
                            onValueChange={mixpanelEventInterval}
                        />
                    </div>
                </div>
                <Card className="p-3 md:p-8">
                    {consumption.length > 0 || isLoadingAnalysisData || isLoadingMarketLocationData ? (
                        <>
                            <EChart
                                renderer="svg"
                                testId="analysis-chart-element"
                                style={{ height: `${chartSize}px` }}
                                isLoading={isLoadingAnalysisData}
                                option={{
                                    grid: [
                                        {
                                            top: paddingTop,
                                            left: paddingX,
                                            right: paddingX,
                                            height: chartHeight,
                                        },
                                        {
                                            top: paddingTop * 2 + chartHeight + graphLegendTopPadding,
                                            left: paddingX,
                                            right: paddingX,
                                            height: chartHeight,
                                        },
                                    ],
                                    title: [
                                        {
                                            left: 'left',
                                            itemGap: 8,
                                            text: graphTopTitle,
                                            textStyle: {
                                                fontSize: 20,
                                                color: '#000',
                                                fontWeight: 'bold',
                                                fontFamily: 'DM Sans',
                                            },
                                            subtext: consumptionSubtitle,
                                            subtextStyle: {
                                                color: '#000',
                                                fontSize: 15,
                                            },
                                        },
                                        {
                                            left: 'left',
                                            itemGap: 8,
                                            text: intl.formatMessage({ id: 'analysis.chartTitle.priceAndCosts' }),
                                            textStyle: {
                                                fontSize: 19,
                                                color: '#000',
                                                fontWeight: 'bold',
                                                fontFamily: 'DM Sans',
                                            },
                                            subtext: costSubtitle,
                                            subtextStyle: {
                                                color: '#000',
                                                fontSize: 15,
                                            },
                                            top: paddingTop + chartHeight + graphLegendTopPadding + gapSubtitle,
                                        },
                                    ],
                                    legend: {
                                        right: 0,
                                        top: graphLegendTopPadding,
                                        selectedMode: limitedChartOptions ? false : 'multiple',
                                        tooltip: {
                                            position: 'top',
                                            show: true,
                                            formatter: intl.formatMessage({ id: 'analysis.chartLegend.tooltip' }),
                                        },
                                        data: [
                                            intl.formatMessage({ id: 'analysis.chartLegend.consumption' }),
                                            pvGenerationIsSet &&
                                                intl.formatMessage({ id: 'analysis.chartLegend.pvGeneration' }),
                                            prices?.length !== 0 &&
                                                intl.formatMessage({ id: 'analysis.chartLegend.marketPrice' }),
                                            energyCostsIsSet &&
                                                !limitedChartOptions &&
                                                intl.formatMessage({ id: 'analysis.chartLegend.costs' }),
                                        ].filter(Boolean),
                                    },
                                    visualMap: [
                                        {
                                            show: false,
                                            type: 'continuous',
                                            seriesIndex: 1,
                                            min: 0,
                                            max: 30,
                                            inRange: {
                                                color: ['#27ae60', '#f1c40f', '#e74c3c'],
                                            },
                                        },
                                    ],
                                    xAxis: [
                                        {
                                            type: 'category',
                                            data: consumption.map(({ timestampStart }) =>
                                                formatAxisDateTimeByInterval(
                                                    intl,
                                                    new Date(Temporal.Instant.from(timestampStart).toString()),
                                                    filterValues.interval,
                                                    consumption.length,
                                                    filterValues.period,
                                                    selectedMarketLocation?.timeZone ?? '',
                                                ),
                                            ),
                                            axisTick: {
                                                alignWithLabel: true,
                                            },
                                        },
                                        {
                                            gridIndex: 1,
                                            type: 'category',
                                            data: consumption.map(({ timestampStart }) =>
                                                formatAxisDateTimeByInterval(
                                                    intl,
                                                    new Date(Temporal.Instant.from(timestampStart).toString()),
                                                    filterValues.interval,
                                                    consumption.length,
                                                    filterValues.period,
                                                    selectedMarketLocation?.timeZone ?? '',
                                                ),
                                            ),
                                            axisLine: {
                                                onZero: false,
                                            },
                                            axisTick: {
                                                alignWithLabel: true,
                                            },
                                        },
                                    ],
                                    yAxis: [
                                        {
                                            type: 'value',
                                            id: 'usage_axis',
                                            name: intl.formatMessage({ id: 'common.kwh' }),
                                            max: highPointChart,
                                            nameTextStyle: {
                                                fontWeight: 'bold',
                                            },
                                        },
                                        {
                                            type: 'value',
                                            id: 'day_ahead_price_axis',
                                            name: intl.formatMessage({ id: 'common.ctPerKwh' }),
                                            position: limitedChartOptions ? 'left' : 'right',
                                            nameTextStyle: {
                                                fontWeight: 'bold',
                                            },
                                            gridIndex: 1,
                                            splitLine: {
                                                show: !energyCostsIsSet || limitedChartOptions,
                                            },
                                            ...yAxisPricesMinMaxOptions,
                                            axisLabel: {
                                                formatter: function (value) {
                                                    return intl.formatNumber(Number(value), {
                                                        maximumFractionDigits: 0,
                                                    });
                                                },
                                            },
                                        },
                                        {
                                            type: 'value',
                                            id: 'total_cost_axis',
                                            name: intl.formatMessage({ id: 'common.eur' }),
                                            position: 'left',
                                            nameTextStyle: {
                                                fontWeight: 'bold',
                                            },
                                            gridIndex: 1,
                                            show: !limitedChartOptions && energyCostsIsSet,
                                            ...yAxisCostsMinMaxOptions,
                                            axisLabel: {
                                                formatter: function (value) {
                                                    return intl.formatNumber(Number(value), {
                                                        maximumFractionDigits: 0,
                                                    });
                                                },
                                            },
                                        },
                                    ],
                                    axisPointer: {
                                        link: [
                                            {
                                                xAxisIndex: 'all',
                                            },
                                        ],
                                    },
                                    tooltip: {
                                        trigger: 'axis',
                                        formatter: generateTooltip,
                                    },
                                    series: [
                                        {
                                            name: intl.formatMessage({ id: 'analysis.chartLegend.consumption' }),
                                            data: consumption.map(({ kwh }) => kwh),
                                            tooltip: {
                                                valueFormatter: value =>
                                                    `${formatValue(value, intl)} ${intl.formatMessage({ id: 'common.kwh' })}`,
                                            },
                                            type: 'bar',
                                            color: '#82b6fc',
                                            emphasis: {
                                                itemStyle: {
                                                    color: '#066EF9',
                                                },
                                            },
                                            markLine: consumptionPeakIsSet
                                                ? {
                                                      symbol: 'none',
                                                      lineStyle: {
                                                          type: 8,
                                                          width: 1.5,
                                                          color: '#E84236',
                                                      },
                                                      label: {
                                                          formatter: intl.formatMessage({
                                                              id: 'analysis.peak',
                                                          }),
                                                          position: 'insideStartTop',
                                                          color: '#E84236',
                                                      },
                                                      data: [
                                                          {
                                                              name: 'Peak',
                                                              yAxis: consumptionPeak,
                                                          },
                                                      ],
                                                  }
                                                : undefined,
                                            zlevel: 1,
                                        },
                                        {
                                            name: intl.formatMessage({ id: 'analysis.chartLegend.marketPrice' }),
                                            data: isLoadingAnalysisData
                                                ? []
                                                : prices?.map(
                                                      ({ price }) =>
                                                          `${(typeof price === 'string' ? parseFloat(price) : price) * 100}`,
                                                  ),
                                            tooltip: {
                                                valueFormatter: value =>
                                                    `${value} ${intl.formatMessage({ id: 'common.ctPerKwh' })}`,
                                            },
                                            type: 'line',
                                            showSymbol: false,
                                            step: 'middle',
                                            xAxisIndex: 1,
                                            yAxisIndex: 1,
                                            color: new graphic.LinearGradient(0, 0, 0, 1, [
                                                {
                                                    offset: 0,
                                                    color: '#e74c3c',
                                                },
                                                {
                                                    offset: 0.05,
                                                    color: '#f1c40f',
                                                },
                                                {
                                                    offset: 0.7,
                                                    color: '#27ae60',
                                                },
                                            ]),
                                            zlevel: 1,
                                        },
                                        {
                                            name: intl.formatMessage({ id: 'analysis.chartLegend.pvGeneration' }),
                                            data: pvGeneration.map(({ kwh }) => kwh),
                                            tooltip: {
                                                valueFormatter: value =>
                                                    `${formatValue(value, intl)} ${intl.formatMessage({ id: 'common.kwh' })}`,
                                            },
                                            symbol: 'none',
                                            type: 'line',
                                            lineStyle: {
                                                width: 0,
                                            },
                                            color: '#EFB13A',
                                            areaStyle: {
                                                color: new graphic.LinearGradient(0, 0, 0, 1, [
                                                    {
                                                        offset: 0,
                                                        color: '#EFB13A',
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: '#DD8B0F',
                                                    },
                                                ]),
                                            },
                                            zlevel: 2,
                                        },
                                        {
                                            name: intl.formatMessage({ id: 'analysis.chartLegend.costs' }),
                                            data: limitedChartOptions ? [] : energyCosts.map(({ sumCost }) => sumCost),
                                            tooltip: {
                                                valueFormatter: value =>
                                                    `${value} ${intl.formatMessage({ id: 'common.eur' })}`,
                                            },
                                            type: 'line',
                                            areaStyle: {
                                                color: '#055E45',
                                                opacity: 0.1,
                                            },
                                            step: 'middle',
                                            symbol: 'none',
                                            color: '#055E45',
                                            xAxisIndex: 1,
                                            yAxisIndex: 2,
                                        },
                                    ],
                                }}
                                settings={{ notMerge: true }}
                            />

                            <p className="text-center text-xs font-bold text-gray-4 mb-4">
                                {intl.formatMessage({ id: 'filterControls.intervals.' + filterValues.interval })}
                            </p>

                            <div className="flex bg-gray-1 p-2 rounded border-2 border-gray-2 gap-x-2 place-items-center">
                                <InfoIcon className="text-primary w-10" />
                                <p className="text-sm">
                                    {intl.formatMessage({ id: 'analysis.consumptionDataDisclaimer' })}
                                </p>
                            </div>
                        </>
                    ) : (
                        <div className="flex place-content-center place-items-center text-lg font-bold h-[375px] w-full text-gray-4">
                            {intl.formatMessage({ id: 'common.noData' })}
                        </div>
                    )}
                </Card>
            </section>
        </EChartAnalysisContext.Provider>
    );
};
