import { UnitKeys, ValueAndUnitType } from '../../types/consumptions.ts';

export const formatConsumptionValue = (value: number): ValueAndUnitType => {
    if (value < 1000) {
        return { value, unit: UnitKeys.Kwh };
    }

    if (value < 1000000) {
        return { value: value / 1000, unit: UnitKeys.MWh };
    }

    return { value: value / 1000000, unit: UnitKeys.GWh };
};
