import { FC, useState } from 'react';
import { GridContainer } from '../components/consumption-grid/GridContainer.tsx';
import { PageHeading } from '../components/PageHeading.tsx';
import { trpc } from '../utils/trpc.ts';
import { Button, PinIcon, LoadingSpinner, EyeIcon, Input } from '@trawa-energy/ui-kit';
import { inferRouterOutputs } from '@trpc/server';
import { AppRouter } from 'apps/portal-api/src/routers/appRouter.ts';
import { Card } from '../components/Card.tsx';
import { useSelectedGroup } from '../hooks/useSelectedGroup/useSelectedGroup.ts';
import { useIntl } from 'react-intl';
import { useFeatureFlags } from '../hooks/useFeatureFlags.ts';
import { ConsumptionGrid } from '../components/consumption-grid/ConsumptionGrid.tsx';
import { useAccountUserInfo } from '../hooks/useAccountUserInfo.ts';
import { KPICardsContainer } from '../components/overview/kpi-cards/KPICardsContainer.tsx';

type GroupItem = inferRouterOutputs<AppRouter>['dashboard']['getMarketLocationsGroup'][number];

export const Overview: FC = () => {
    const intl = useIntl();
    const [selectedGroupId, setSelectedGroupId] = useState<string>('');
    const [searchValue, setSearchValue] = useState<string>('');
    const { isClusterEnabled } = useFeatureFlags();
    const { isRealEstateUser, account, contractPartnerName } = useAccountUserInfo();
    const { data: groups, isLoading: isLoadingGroups } = trpc.dashboard.getMarketLocationsGroup.useQuery({});
    const renderGroup = useSelectedGroup({
        groupItems: groups,
        selectedItemId: selectedGroupId,
        searchValue: searchValue,
    });

    const paddingTailwindClasses = 'px-3 py-8 md:px-8';

    const tableRowClassName =
        'transition odd:bg-primary-1 *:px-4 *:lg:px-6 *:2xl:px-6 *:py-2 *:border-primary-2 *:border-t [&:last-child>*]:border-b [&:first-child>*]:border-t-gray-3 [&:last-child>*]:border-b-gray-3 first:*:border-l-gray-3 last:*:border-r-gray-3 first:*:border-l last:*:border-r [&:first-child>:first-child]:rounded-tl-md [&:first-child>:last-child]:rounded-tr-md [&:last-child>:first-child]:rounded-bl-md [&:last-child>:last-child]:rounded-br-md';

    const showGridOnClick = (entry: GroupItem) => {
        if (selectedGroupId === entry.clusterId) {
            setSelectedGroupId('');
            return;
        }

        setSelectedGroupId(entry.clusterId);
    };

    const btnLabelIcon = () => {
        return (
            <p className="flex">
                {selectedGroupId
                    ? intl.formatMessage({ id: 'overview.btnOnViewGrid' })
                    : intl.formatMessage({ id: 'overview.btnOffViewGrid' })}
                <span className="pl-2">{selectedGroupId ? <EyeIcon /> : <PinIcon />}</span>
            </p>
        );
    };

    if (isLoadingGroups || groups === undefined) {
        return (
            <GridContainer className={paddingTailwindClasses}>
                <LoadingSpinner />
            </GridContainer>
        );
    }

    return (
        <section>
            <PageHeading title={'overview.title'} />
            <KPICardsContainer />
            {isClusterEnabled && groups && groups.length > 0 ? (
                <>
                    <div className="w-[350px] pb-5">
                        <label>
                            {intl.formatMessage({ id: 'overview.searchLabel' })}

                            <Input
                                type="text"
                                value={searchValue}
                                onChange={e => setSearchValue(e.target.value)}
                                onClear={() => setSearchValue('')}
                                label={intl.formatMessage({ id: 'overview.searchLabel' })}
                                placeholder={
                                    selectedGroupId
                                        ? groups.filter(group => group.clusterId === selectedGroupId)[0].nameGroup
                                        : intl.formatMessage({ id: 'overview.searchPlaceholder' })
                                }
                                isSearch
                                clearable
                                clearButtonLabel={intl.formatMessage({ id: 'overview.searchClearButtonLabel' })}
                                disabled={selectedGroupId !== ''}
                                className="bg-white"
                            />
                        </label>
                    </div>

                    <Card>
                        <table className="text-sm lg:text-base w-full border-separate border-spacing-0">
                            {/*
                            We are going to disable this title for now, until we have a better title label for the table 
                            <thead>
                                <tr>
                                    <th scope="col" className="text-start pb-5 pl-6 font-light">
                                        {intl.formatMessage({ id: 'overview.groupTableTitle' })}
                                    </th>
                                    <th scope="col"></th>
                                </tr>
                            </thead> */}
                            <tbody>
                                {renderGroup?.map(entry => (
                                    <tr key={entry.nameGroup} className={tableRowClassName}>
                                        <td className="text-start font-bold">{entry.nameGroup}</td>
                                        <td className="text-end">
                                            <Button
                                                size="default"
                                                variant="outline"
                                                onClick={() => showGridOnClick(entry)}
                                            >
                                                {btnLabelIcon()}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Card>
                    {selectedGroupId !== '' && (
                        <>
                            <br />
                            <ConsumptionGrid groupId={selectedGroupId} />
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="text-xl md:text-2xl font-bold mb-4">
                        {intl.formatMessage(
                            { id: 'consumptionView.subtitle' },
                            { partner: isRealEstateUser ? contractPartnerName : account?.name },
                        )}
                    </div>
                    <ConsumptionGrid />
                </>
            )}
        </section>
    );
};
