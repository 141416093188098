import Analytics, { AnalyticsInstance, AnalyticsPlugin } from 'analytics';
import { standaloneTrpcClient } from '../routing/standaloneQueryClient.ts';
import { IdentifyParams, PageParams, TrackMethod, TrackParams } from './events.ts';

const analyticsDisabledStorageKey = 'analyticsDisabled';

const url = new URL(window.location.href);
// This is implemented to be sticky, until the user forces it to false
const analyticsDisabledParameterValue = url.searchParams.get('analyticsDisabled');

if (analyticsDisabledParameterValue === 'true') {
    window.localStorage.setItem(analyticsDisabledStorageKey, 'true');
} else if (analyticsDisabledParameterValue === 'false') {
    window.localStorage.removeItem(analyticsDisabledStorageKey);
}

const analyticsDisabledInLocalStorage = window.localStorage.getItem(analyticsDisabledStorageKey) === 'true';

export const analytics = Analytics({
    app: 'Customer Portal',
    plugins: [mixpanelProxyPlugin()],
}) as unknown as Omit<AnalyticsInstance, 'track'> & { track: TrackMethod };

if (analyticsDisabledInLocalStorage) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (analytics.plugins.disable as any)('mixpanel-proxy');
}

function mixpanelProxyPlugin(): AnalyticsPlugin {
    return {
        /* All plugins require a name */
        name: 'mixpanel-proxy',
        /* Everything else below this is optional depending on your plugin requirements */
        config: {},
        initialize: () => {
            // load provider script to page
        },
        page: ({ payload: { anonymousId, properties } }: { payload: PageParams }) => {
            standaloneTrpcClient.tracking.page.mutate({
                url: new URL(properties.path, window.location.origin).toString(),
                anonymousId,
            });
        },
        track: ({ payload: { event, anonymousId, properties } }: { payload: TrackParams }) => {
            standaloneTrpcClient.tracking.track.mutate({
                eventName: event,
                anonymousId,
                customData: properties,
            });
        },
        identify: ({ payload: { userId } }: { payload: IdentifyParams }) => {
            standaloneTrpcClient.tracking.identify.mutate({ userId: userId! });
        },
        loaded: () => {
            // return boolean so analytics knows when it can send data to third-party
            return true;
        },
    };
}
