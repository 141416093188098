import { KpiKeys, KpiType, ValueAndUnitType } from '../../types/consumptions.ts';
import { formatConsumptionValue } from './formatConsumptionValue.ts';
import { formatEmissionValue } from './formatEmissionValue.ts';

export const EMPTY_VALUE = { value: 0, unit: '' };

export const formatValueAndUnitKpi = (type: KpiType, value: number) => {
    const valueAndUnit: Record<KpiKeys, ValueAndUnitType> = {
        [KpiKeys.Consumption]: formatConsumptionValue(value),
        [KpiKeys.Emission]: formatEmissionValue(value),
        [KpiKeys.Solar]: EMPTY_VALUE,
    };

    return valueAndUnit[type];
};
