import { FC, PropsWithChildren } from 'react';

import { AnimatePresence } from 'framer-motion';

export const GridContainer: FC<PropsWithChildren<{ className?: string; isTvScreen?: boolean }>> = ({
    children,
    className = '',
    isTvScreen = false,
}) => {
    return (
        <AnimatePresence mode="wait">
            <div
                data-testid="grid_container"
                className={`${className} ${!isTvScreen ? 'border-y sm:border-x sm:rounded-xl bg-card' : 'h-full flex flex-col justify-around'} border-muted-foreground overflow-auto mx-[-0.5rem] sm:mx-0`}
            >
                {children}
            </div>
        </AnimatePresence>
    );
};
