import { trpc } from '../utils/trpc';
import { useMaybeContractPartnerId } from '../userSettings.ts';
import { getPlainDateTimeFromString } from '../utils/getPlainDateTimeFromString.ts';
import { useEffect } from 'react';
import { analytics } from '../analytics/analytics.ts';
import { EventName } from '../analytics/events.ts';
import { Temporal } from 'temporal-polyfill';

export enum DisplayGrid {
    TV = 'tv',
    DESKTOP = 'desktop',
}

const getTRPCProcedureAPI = {
    [DisplayGrid.DESKTOP]: trpc.consumptionGrid,
    [DisplayGrid.TV]: trpc.tvConsumption,
};

type useLastDayWithDataParams = {
    display?: `${DisplayGrid}`;
};

const refetchInterval = Temporal.Duration.from({ hours: 12 }).total({ unit: 'millisecond' });

export const useLastDayWithData = ({ display = DisplayGrid.DESKTOP }: useLastDayWithDataParams) => {
    const contractPartnerId = useMaybeContractPartnerId();

    const { data: account } = trpc.account.getCurrent.useQuery({}, { enabled: display === DisplayGrid.DESKTOP });
    const { data, isLoading, isError } = getTRPCProcedureAPI[display].getLatestDayWithCompleteData.useQuery(
        { contractPartnerId },
        { refetchInterval },
    );

    useEffect(() => {
        if (data && data?.date && account && display === DisplayGrid.TV) {
            /* we need the account ID, as this is not auto added as this is a unprotected route */
            analytics.track(EventName.TVScreenViewed, {
                'Date shown': data.date.toString(),
                'Account ID': account?.id,
            });
        }
    }, [data, display, account]);

    return {
        data: data?.date ? getPlainDateTimeFromString(data.date) : undefined,
        isLoading,
        isError,
    };
};
