import React, { ReactNode } from 'react';
import { ChartLineIcon, SolarPpaIcon, TreesIcon } from '@trawa-energy/ui-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { Format } from '../../../utils/formatting';
import { KpiKeys, KpiType } from '../../../types/consumptions.ts';

type KPIBoxProps = {
    title: string;
    type: KpiType;
    data: { title: string; data: number | undefined }[];
};

type RenderType = {
    messageId: string;
    icon: ReactNode;
};

export const KPIBox = ({ title, type, data }: KPIBoxProps) => {
    const intl = useIntl();
    const typeToRender: Record<KpiKeys, RenderType> = {
        [KpiKeys.Consumption]: {
            messageId: 'tv.kpi.kwhValue',
            icon: <ChartLineIcon className="inline text-gray-3" />,
        },
        [KpiKeys.Emission]: {
            messageId: 'tv.kpi.co2Value',
            icon: <TreesIcon className="inline text-primary-green-3" />,
        },
        [KpiKeys.Solar]: {
            messageId: 'tv.kpi.kwhValue',
            icon: <SolarPpaIcon width="24px" className="inline" />,
        },
    };

    return (
        <div
            className="dark:bg-black border border-gray-5 p-5 rounded-xl flex flex-col gap-4 lg:gap-5 w-full"
            data-testid={type}
        >
            <h2 className="font-bold text-md lg:text-xl">{title}</h2>
            {data.map((entry, i) => {
                return (
                    <div key={i}>
                        <p className="text-xs lg:text-[15px]">{entry.title}</p>
                        <p className="flex gap-2 lg:gap-[10px] items-center">
                            <span>{typeToRender[type].icon}</span>
                            <span className="text-2xl lg:text-3xl font-bold">
                                <FormattedMessage
                                    id={typeToRender[type].messageId}
                                    values={{
                                        value: entry.data
                                            ? intl.formatNumber(Format.formatValueAndUnitKPI(type, entry!.data).value, {
                                                  maximumFractionDigits: 2,
                                              })
                                            : '',
                                        sub: chunk => <sub>{chunk}</sub>,
                                        unit: Format.formatValueAndUnitKPI(type, entry.data!).unit,
                                    }}
                                />
                            </span>
                        </p>
                    </div>
                );
            })}
        </div>
    );
};
