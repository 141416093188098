import { inferRouterOutputs } from '@trpc/server';
import { AppRouter } from '@trawa-energy/portal-api/appRouter';

export type VolumeEntry = inferRouterOutputs<AppRouter>['dashboard']['getVolumeEntries'][0];

export type SupplyByInterval = inferRouterOutputs<AppRouter>['dashboard']['getSupplyProductsByInterval'][0];

export enum KpiKeys {
    Consumption = 'consumption',
    Emission = 'emission',
    Solar = 'solar',
}

export enum UnitKeys {
    Kwh = 'kWh',
    MWh = 'MWh',
    GWh = 'GWh',
    Tones = 't',
}

export type PowerUnit = 'kWh' | 'GWh';

export type KpiType = `${KpiKeys}`;
export type ValueAndUnitType = {
    value: number;
    unit: string;
};

export enum SolidSupplyProductValues {
    wind = 'wind',
    solar = 'solar',
    futures = 'futures',
}

export type SupplyProductType = SolidSupplyProductValues | 'spot';
